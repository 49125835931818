import React, { FC } from 'react';
import { UserScanningPlan } from '../../../common/model/dto/scan-sequences/user-scanning-plan';
import styled from 'styled-components';
import Variables from '../../../design-system/variables';
import Colours from '../../../design-system/colours';
import OvUserScanningPlanTable from './OvUserScanningPlanTable';
import { DailyData } from '../../../common/model/dto/daily-data';

interface OvUserScanningPlanListProps {
  userScanningPlans: UserScanningPlan[];
  dailyData?: DailyData[];
}

const OvUserScanningPlanList: FC<OvUserScanningPlanListProps> = ({
  userScanningPlans,
  dailyData,
}) => {
  return (
    <TablesContainer>
      {userScanningPlans?.map((userScanningPlan) => (
        <StyledTableContainer>
          <HeaderContainer>
            <StyledTableTitle>{`${
              userScanningPlan.name ?? '-'
            }`}</StyledTableTitle>
          </HeaderContainer>
          <OvUserScanningPlanTable
            userScanningPlan={userScanningPlan}
            dailyDataArray={dailyData}
          />
        </StyledTableContainer>
      ))}
    </TablesContainer>
  );
};

export default OvUserScanningPlanList;

const StyledTableContainer = styled.div`
  margin-left: 1rem;
  box-shadow: ${Variables.boxShadow.defaultBox};
  background-color: ${Colours.WHITE};
  padding: 1.5rem;
  border-radius: ${Variables.borderRadius.SMALL};
`;

const TablesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const StyledTableTitle = styled.h5`
  padding: 0;
  margin: 0;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
