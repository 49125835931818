import React, { ReactElement, ReactNode } from 'react';
import styled from 'styled-components';
import Variables from '../../../design-system/variables';
import InfoIcon from '@mui/icons-material/Info';
import { Tooltip } from '@mui/material';

interface OvPageTitleProps {
  icon: ReactElement;
  title: string;
  infoTooltip?: string;
  styles?: React.CSSProperties;
  children?: ReactNode;
}

const OvPageTitle: React.FC<OvPageTitleProps> = ({
  icon,
  title,
  infoTooltip,
  styles,
  children,
}) => {
  return (
    <Header style={styles}>
      <Title>
        {icon && <IconWrapper>{icon}</IconWrapper>}
        {title}
        {infoTooltip && (
          <Tooltip title={infoTooltip}>
            <StyledInfoIcon />
          </Tooltip>
        )}
      </Title>

      {children}
    </Header>
  );
};

export default OvPageTitle;

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.h5`
  margin: 0;
  display: flex;
  align-items: center;
  font-size: ${Variables.fontSizes.LARGE};
  font-weight: bold;
  gap: 0.5rem;
`;

const IconWrapper = styled.span`
  display: flex;
  align-items: center;
`;

const StyledInfoIcon = styled(InfoIcon)`
  && {
    width: 1rem;
    height: 1rem;
  }
`;
