import { AdminNpsSurveyState } from '../states/admin-nps-survey.state';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ErrorUtils } from '../../common/utils/services/error-utils';
import { NpsSurveySettings } from '../../common/model/dto/survey/nps-survey-settings';
import {
  createNpsSurvey,
  getCurrentNpsSurvey,
  getNpsSurveyById,
  getNpsSurveySettings,
  updateNpsSurvey,
  updateNpsSurveySettings,
} from '../thunks/admin/admin-nps-survey.thunk';
import { AxiosError } from 'axios';
import { Survey } from '../../common/model/dto/survey/survey';

const initialState: AdminNpsSurveyState = {
  loading: false,
  error: '',
  builderError: '',
  npsSurveySettings: null,
  currentNpsSurvey: null,
};

const adminNpsSurveySlice = createSlice({
  name: 'adminNpsSurvey',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // @ts-ignore
    builder
      .addCase(getCurrentNpsSurvey.pending, (state) => {
        state.loading = true;
        state.error = '';
      })
      .addCase(
        getCurrentNpsSurvey.fulfilled,
        (state, action: PayloadAction<Survey>) => {
          state.currentNpsSurvey = action.payload;
          state.loading = false;
        }
      )
      .addCase(
        getCurrentNpsSurvey.rejected,
        (state, action: PayloadAction<AxiosError>) => {
          state.error = ErrorUtils.parseAxiosError(action);
          state.loading = false;
        }
      );

    // @ts-ignore
    builder
      .addCase(getNpsSurveyById.pending, (state) => {
        state.loading = true;
        state.builderError = '';
      })
      .addCase(
        getNpsSurveyById.fulfilled,
        (state, action: PayloadAction<Survey>) => {
          state.currentNpsSurvey = action.payload;
          state.loading = false;
        }
      )
      .addCase(
        getNpsSurveyById.rejected,
        (state, action: PayloadAction<AxiosError>) => {
          state.builderError = ErrorUtils.parseAxiosError(action);
          state.loading = false;
        }
      );

    // @ts-ignore
    builder
      .addCase(createNpsSurvey.pending, (state) => {
        state.loading = true;
        state.builderError = '';
      })
      .addCase(
        createNpsSurvey.fulfilled,
        (state, action: PayloadAction<Survey>) => {
          state.currentNpsSurvey = action.payload;
          state.loading = false;
        }
      )
      .addCase(
        createNpsSurvey.rejected,
        (state, action: PayloadAction<AxiosError>) => {
          state.builderError = ErrorUtils.parseAxiosError(action);
          state.loading = false;
        }
      );

    // @ts-ignore
    builder
      .addCase(updateNpsSurvey.pending, (state) => {
        state.loading = true;
        state.builderError = '';
      })
      .addCase(
        updateNpsSurvey.fulfilled,
        (state, action: PayloadAction<Survey>) => {
          state.currentNpsSurvey = action.payload;
          state.loading = false;
        }
      )
      .addCase(
        updateNpsSurvey.rejected,
        (state, action: PayloadAction<AxiosError>) => {
          state.builderError = ErrorUtils.parseAxiosError(action);
          state.loading = false;
        }
      );

    // @ts-ignore
    builder
      .addCase(getNpsSurveySettings.pending, (state) => {
        state.loading = true;
        state.error = '';
      })
      .addCase(
        getNpsSurveySettings.fulfilled,
        (state, action: PayloadAction<NpsSurveySettings>) => {
          state.npsSurveySettings = action.payload;
          state.loading = false;
        }
      )
      .addCase(
        getNpsSurveySettings.rejected,
        (state, action: PayloadAction<AxiosError>) => {
          state.error = ErrorUtils.parseAxiosError(action);
          state.loading = false;
        }
      );

    // @ts-ignore
    builder
      .addCase(updateNpsSurveySettings.pending, (state) => {
        state.loading = true;
        state.error = '';
      })
      .addCase(
        updateNpsSurveySettings.fulfilled,
        (state, action: PayloadAction<NpsSurveySettings>) => {
          state.npsSurveySettings = action?.payload;
          state.loading = false;
        }
      )
      .addCase(
        updateNpsSurveySettings.rejected,
        (state, action: PayloadAction<AxiosError>) => {
          state.error = ErrorUtils.parseAxiosError(action);
          state.loading = false;
        }
      );
  },
});

export default adminNpsSurveySlice.reducer;
