import React, { FC } from 'react';
import OvNpsSurveyQuestion from '../atoms/OvNpsSurveyQuestion';
import { Question } from '../../../common/model/dto/survey/question';
import styled from 'styled-components';
import Colours from '../../../design-system/colours';
import Variables from '../../../design-system/variables';
import { QuestionTypeEnum } from '../../../common/model/dto/survey/question-type.enum';
import OvTextSurveyQuestion from '../atoms/OvTextSurveyQuestion';
import { SurveyQuestionResponse } from '../../../common/model/dto/survey/survey-question-response';

export interface OvSurveyQuestionProps {
  question: Question;
  onResponseChange: (questionResponse: SurveyQuestionResponse) => void;
  value?: any;
}

const OvSurveyQuestion: FC<OvSurveyQuestionProps> = ({
  question: { id, label, type, required, question_options },
  onResponseChange,
  value,
}) => {
  let questionComponent = null;

  const handleValueChange = (value: any) => {
    const questionResponse: SurveyQuestionResponse = {
      question_id: id,
      value,
    };

    if (onResponseChange) {
      onResponseChange(questionResponse);
    }
  };

  switch (type) {
    case QuestionTypeEnum.NPS:
      questionComponent = (
        <OvNpsSurveyQuestion
          value={value}
          onChange={handleValueChange}
          leftLabel={
            question_options && question_options?.length > 0
              ? question_options[0].label
              : null
          }
          rightLabel={
            question_options && question_options?.length > 1
              ? question_options[1].label
              : null
          }
        />
      );
      break;
    case QuestionTypeEnum.TEXT:
      questionComponent = (
        <OvTextSurveyQuestion value={value} onChange={handleValueChange} />
      );
      break;
    default:
      questionComponent = (
        <OvTextSurveyQuestion value={value} onChange={handleValueChange} />
      );
      break;
  }

  return (
    <>
      <QuestionLabel>
        {label} {required && '*'}
      </QuestionLabel>
      <QuestionContainer>{questionComponent}</QuestionContainer>
    </>
  );
};

export default OvSurveyQuestion;

const QuestionLabel = styled.h3`
  color: ${Colours.OV_BASE_LIGHT};
  font-size: ${Variables.fontSizes.MLARGE};
  margin-bottom: 1.25rem;
  margin-top: 0;
  text-align: center;
`;

const QuestionContainer = styled.div`
  display: flex;
  justify-content: center;
`;
