import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Question } from '../../../common/model/dto/survey/question';
import { SurveyType } from '../../../common/model/type/survey-type.enum';
import Colours from '../../../design-system/colours';
import Variables from '../../../design-system/variables';
import { useTranslation } from 'react-i18next';
import OvTextField from '../atoms/OvTextField';
import { QuestionTypeEnum } from '../../../common/model/dto/survey/question-type.enum';
import OvQuestionTypeSelector from '../atoms/OvQuestionTypeSelector';
import Checkbox from '@mui/material/Checkbox';
import breakpoints from '../../../design-system/breakpoints';
import OvNpsSurveyQuestion from '../atoms/OvNpsSurveyQuestion';
import OvSurveyBuilderQuestionList from './OvSurveyBuilderQuestionList';
import OvButton from '../atoms/OvButton';
import AddIcon from '@mui/icons-material/Add';

const defaultQuestion: Question = {
  id: '1',
  label: '',
  required: false,
  type: QuestionTypeEnum.TEXT,
  question_options: [
    {
      label: '',
    },
    {
      label: '',
    },
  ],
  represent_nps_score: false,
};

export interface OvSurveyBuilderQuestionsProps {
  questions?: Question[];
  surveyType: SurveyType;
  onChange: (values: Question[], isValid?: boolean) => void;
}

const OvSurveyBuilderQuestions: FC<OvSurveyBuilderQuestionsProps> = ({
  questions,
  surveyType,
  onChange,
}) => {
  const { t } = useTranslation();
  const [activeQuestion, setActiveQuestion] = useState<Question>();

  useEffect(() => {
    setActiveQuestion(questions?.length ? questions[0] : defaultQuestion);

    if (!questions?.length) {
      onChange([defaultQuestion], isCurrentQuestionValid());
    }
    // eslint-disable-next-line
  }, []);

  const changeProp = <T,>(propName: string, value: T) => {
    setActiveQuestion((question) => {
      const updatedQuestion = {
        ...(question as Question),
        [propName]: value,
      };

      if (questions?.length) {
        onChange(
          questions?.map((q) => (q.id === question?.id ? updatedQuestion : q)),
          isCurrentQuestionValid()
        );
      }

      return updatedQuestion;
    });
  };

  const changeLabel = (event: ChangeEvent<HTMLInputElement>) => {
    changeProp('label', event.target.value);
  };

  const changeType = (type: QuestionTypeEnum) => {
    changeProp('type', type);
  };

  const changeRequired = (event: ChangeEvent<HTMLInputElement>) => {
    changeProp('required', event.target.checked);
  };

  const changeOptionLabel = (
    index: number,
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = event.target.value;

    changeProp(
      'question_options',
      activeQuestion?.question_options?.map((option, currentIdx) => ({
        ...option,
        label: currentIdx === index ? value : option.label,
      }))
    );
  };

  const changeRepresentNpsScore = (event: ChangeEvent<HTMLInputElement>) => {
    changeProp('represent_nps_score', event.target.checked);
  };

  const selectQuestion = (question: Question) => {
    if (isCurrentQuestionValid()) {
      setActiveQuestion(question);
    }
  };

  const removeQuestion = (question: Question) => {
    if (questions?.length) {
      const filteredQuestions = questions.filter((q) => q.id !== question.id);

      setActiveQuestion(
        filteredQuestions.length ? filteredQuestions[0] : defaultQuestion
      );

      onChange(filteredQuestions, isCurrentQuestionValid());
    }
  };

  const addQuestion = () => {
    if (isCurrentQuestionValid()) {
      const newQuestion: Question = {
        ...defaultQuestion,
        id: `${(questions as Question[])?.length + 1}`,
      };

      setActiveQuestion(newQuestion);
      onChange([...(questions || []), newQuestion], isCurrentQuestionValid());
    }
  };

  const isCurrentQuestionValid = (): boolean => {
    return !!activeQuestion?.label;
  };

  return (
    <Wrapper>
      <SetupWrapper>
        <SectionTitle>{t('survey.question.setup')}</SectionTitle>
        <FormLabel>
          <FormLabelText>{t('survey.question.text')}</FormLabelText>
          <StyledTextField
            error={!activeQuestion?.label}
            helperText={!activeQuestion?.label && t('common.requiredField')}
            value={activeQuestion?.label}
            onChange={changeLabel}
          />
        </FormLabel>

        <StyledText>{t('survey.question.type')}</StyledText>

        <OvQuestionTypeSelectorWrapper>
          <OvQuestionTypeSelector
            selectedType={activeQuestion?.type}
            onSelect={changeType}
          />
        </OvQuestionTypeSelectorWrapper>

        <FormLabel>
          <FormLabelText>{t('survey.question.required')}</FormLabelText>

          <Checkbox
            checked={!!activeQuestion?.required}
            onChange={changeRequired}
          />
        </FormLabel>

        {surveyType === SurveyType.NPS &&
          activeQuestion?.type === QuestionTypeEnum.NPS &&
          (!questions?.some(({ represent_nps_score }) => represent_nps_score) ||
            activeQuestion.represent_nps_score) && (
            <FormLabel>
              <FormLabelText>
                {t('survey.question.representNpsScore')}
              </FormLabelText>

              <Checkbox
                checked={!!activeQuestion?.represent_nps_score}
                onChange={changeRepresentNpsScore}
              />
            </FormLabel>
          )}

        <HorizontalSeparator />

        <SectionTitle>{t('survey.question.details')}</SectionTitle>

        {activeQuestion?.type === QuestionTypeEnum.TEXT && (
          <InfoText>{t('survey.question.maxTextLength')}</InfoText>
        )}

        {activeQuestion?.type === QuestionTypeEnum.NPS && (
          <>
            <FormLabel>
              <FormLabelText style={{ width: '12rem' }}>
                {t('survey.question.labels.left')}
              </FormLabelText>
              <StyledTextField
                value={activeQuestion?.question_options?.[0].label}
                onChange={(event) => changeOptionLabel(0, event)}
              />
            </FormLabel>

            <FormLabel>
              <FormLabelText style={{ width: '12rem' }}>
                {t('survey.question.labels.right')}
              </FormLabelText>
              <StyledTextField
                value={activeQuestion?.question_options?.[1].label}
                onChange={(event) => changeOptionLabel(1, event)}
              />
            </FormLabel>

            <NpsQuestionWrapper>
              <OvNpsSurveyQuestion
                leftLabel={activeQuestion?.question_options?.[0].label}
                rightLabel={activeQuestion?.question_options?.[1].label}
              />
            </NpsQuestionWrapper>
          </>
        )}
      </SetupWrapper>
      <QuestionsWrapper>
        <SectionTitle>{t('survey.question.current')}</SectionTitle>

        <OvSurveyBuilderQuestionList
          questions={questions}
          activeQuestion={activeQuestion}
          isCurrentQuestionValid={isCurrentQuestionValid()}
          onSelectQuestion={selectQuestion}
          onRemoveQuestion={removeQuestion}
        />

        <CtaContainer>
          <StyledOvButton onClick={addQuestion}>
            <AddIcon />
            {t('survey.question.add')}
          </StyledOvButton>
        </CtaContainer>
      </QuestionsWrapper>
    </Wrapper>
  );
};

export default OvSurveyBuilderQuestions;

const Wrapper = styled.div`
  display: flex;
  gap: 1rem;
  width: 100%;
`;

const SetupWrapper = styled.div`
  border-right: 1px solid ${Colours.OV_LIGHT_GRAY};
  padding: 0 1.5rem;
  flex-grow: 1;
`;

const QuestionsWrapper = styled.div`
  padding: 0 1.5rem 1.5rem 1.5rem;
  flex: 0 0 40%;
`;

const SectionTitle = styled.p`
  font-size: ${Variables.fontSizes.LARGE};
  font-weight: bold;
  color: ${Colours.OV_BASE_LIGHT};
  margin-top: 0;
  margin-bottom: 0.5rem;
`;

const FormLabel = styled.label`
  display: flex;
  font-size: ${Variables.fontSizes.MEDIUM};
  gap: 0.5rem;
  margin-bottom: 1.5rem;
`;

const FormLabelText = styled.span`
  white-space: nowrap;
  margin-top: 0.875rem;
`;

const StyledTextField = styled(OvTextField)`
  && {
    .MuiFormHelperText-root.Mui-error {
      background: ${Colours.OV_WHITE};
      margin: 0;
      padding: 0.25rem 0.75rem;
    }

    &.MuiFormControl-root {
      width: 100%;

      .MuiInputBase-root {
        width: 100%;
      }
    }

    @media (min-width: ${breakpoints.sm}) {
      max-width: 26rem;
    }
  }
`;

const StyledText = styled.p`
  margin-top: 0;
  margin-bottom: 1.5rem;
  font-size: ${Variables.fontSizes.MEDIUM};
`;

const InfoText = styled(StyledText)`
  margin-top: 1.5rem;
  margin-bottom: 0;
`;

const OvQuestionTypeSelectorWrapper = styled.div`
  margin-bottom: 1rem;
  display: block;
`;

const HorizontalSeparator = styled.hr`
  width: 100%;
  background-color: ${Colours.OV_LIGHT_GRAY};
  color: ${Colours.OV_LIGHT_GRAY};
  margin: 1rem 0;
`;

const NpsQuestionWrapper = styled.div`
  width: auto;
  display: flex;
  margin-top: 2rem;
`;

const CtaContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
`;

const StyledOvButton = styled(OvButton)`
  && {
    padding: 0.25rem 1rem;
    border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
    transition: none;
    font-weight: bold;
    display: flex;
    align-items: center;
    gap: 0.25rem;
  }
`;
