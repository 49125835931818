import React, { FC, useState } from 'react';
import OvBottomActionDrawer from './OvBottomActionDrawer';
import OvSurvey from '../organisms/OvSurvey';
import { Survey } from '../../../common/model/dto/survey/survey';
import { QuestionTypeEnum } from '../../../common/model/dto/survey/question-type.enum';
import { useTranslation } from 'react-i18next';

const OvProminentActions: FC = () => {
  const { t } = useTranslation();
  const [isOpenBottomActionDrawer, setIsOpenBottomActionDrawer] =
    useState<boolean>(false);

  // TODO this is just mock, will come from backend later
  const mockSurvey: Survey = {
    id: 'survey1',
    title: t('survey.label'),
    description: t('survey.description'),
    questions: [
      {
        id: 'q1',
        type: QuestionTypeEnum.NPS,
        label: 'How likely are you to recommend Oova to a colleague?',
        required: true,
        question_options: [
          {
            label: t('common.notLikely'),
          },
          {
            label: t('common.veryLikely'),
          },
        ],
      },
      {
        id: 'q2',
        type: QuestionTypeEnum.TEXT,
        label: 'What is the most important reason for your score?',
        required: false,
      },
    ],
    submit_screen: {
      title: 'Thanks for your help!',
      description:
        "We genuinely appreciate the time you've taken to share your thoughts.",
    },
  };

  return (
    <OvBottomActionDrawer
      isOpen={isOpenBottomActionDrawer}
      onClose={() => setIsOpenBottomActionDrawer(false)}
      canClose
    >
      <OvSurvey survey={mockSurvey} />
    </OvBottomActionDrawer>
  );
};

export default OvProminentActions;
