import React, { FC, useState } from 'react';
import styled from 'styled-components';
import Colours from '../../../design-system/colours';
import { useTranslation } from 'react-i18next';
import { SurveyType } from '../../../common/model/type/survey-type.enum';
import { Survey } from '../../../common/model/dto/survey/survey';
import { Step, StepLabel, Stepper } from '@mui/material';
import OvSurveyBuilderSettings from '../molecules/OvSurveyBuilderSettings';
import OvSurveyBuilderQuestions from '../molecules/OvSurveyBuilderQuestions';
import OvButton from '../atoms/OvButton';
import Variables from '../../../design-system/variables';
import { Question } from '../../../common/model/dto/survey/question';
import InfoIcon from '@mui/icons-material/Info';
import { QuestionTypeEnum } from '../../../common/model/dto/survey/question-type.enum';

export interface OvSurveyBuilderProps {
  survey?: Survey;
  surveyType: SurveyType;
  onChange: (survey: Partial<Survey>) => void;
  onSave: (survey: Survey) => void;
}

const OvSurveyBuilder: FC<OvSurveyBuilderProps> = ({
  survey,
  surveyType,
  onChange,
  onSave,
}) => {
  const { t } = useTranslation();

  const [activeStep, setActiveStep] = useState(0);
  const steps = ['survey.settings', 'survey.questions'];
  const [surveySettings, setSurveySettings] = useState<{
    settings: Partial<Survey> | null;
    isValid: boolean;
  }>({ settings: null, isValid: false });
  const [surveyQuestions, setSurveyQuestions] = useState<{
    questions: Question[] | null;
    isValid: boolean;
  }>({ questions: null, isValid: false });

  const handleSurveySettingsChange = (
    settings: Partial<Survey>,
    isValid?: boolean
  ) => {
    setSurveySettings({ settings, isValid: isValid || false });
    onChange(settings);
  };

  const handleSurveyQuestionsChange = (
    questions: Question[],
    isValid?: boolean
  ) => {
    setSurveyQuestions({ questions, isValid: isValid || false });
    onChange({
      ...survey,
      questions,
    });
  };

  const handleGoToNextStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleGoToPrevStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSave = () => {
    onSave({
      ...surveySettings.settings,
      questions: surveyQuestions?.questions as Question[],
      survey_type: surveyType,
    });
  };

  const canNavigateToQuestions = () => {
    const { title, submit_screen } = surveySettings.settings as Survey;

    return title && submit_screen?.title;
  };

  const areQuestionsValid = (questions: Question[] | null): boolean => {
    if (!questions || questions.length === 0) {
      return false;
    }

    if (!questions.every(({ label }) => label?.trim() !== '')) {
      return false;
    }

    return !(
      surveyType === SurveyType.NPS &&
      !questions.some(
        (question) =>
          question.type === QuestionTypeEnum.NPS && question.represent_nps_score
      )
    );
  };

  return (
    <>
      <StyledStepper activeStep={activeStep}>
        {steps.map((label) => {
          const stepProps: { completed?: boolean } = {};

          return (
            <Step key={label} {...stepProps}>
              <StyledStepLabel>{t(label)}</StyledStepLabel>
            </Step>
          );
        })}
      </StyledStepper>

      {activeStep === 0 ? (
        <OvSurveyBuilderSettings
          survey={survey}
          surveyType={surveyType}
          onChange={handleSurveySettingsChange}
        />
      ) : (
        <OvSurveyBuilderQuestions
          questions={survey?.questions}
          surveyType={surveyType}
          onChange={handleSurveyQuestionsChange}
        />
      )}

      {activeStep === 1 && surveyType === SurveyType.NPS && (
        <InfoText>
          <InfoIcon />
          {t('common.clinicFields.npsScoreRepresentDescription')}
        </InfoText>
      )}

      <ActionContainer>
        {activeStep === 0 && (
          <StyledOvButton
            disabled={!surveySettings?.isValid || !canNavigateToQuestions()}
            onClick={handleGoToNextStep}
          >
            {t('survey.questions')}
          </StyledOvButton>
        )}

        {activeStep === 1 && (
          <>
            <StyledOvButton onClick={handleGoToPrevStep}>
              {t('survey.settings')}
            </StyledOvButton>

            <StyledOvButton
              disabled={!areQuestionsValid(surveyQuestions?.questions)}
              onClick={handleSave}
            >
              {t('common.actions.save')}
            </StyledOvButton>
          </>
        )}
      </ActionContainer>
    </>
  );
};

export default OvSurveyBuilder;

const StyledStepper = styled(Stepper)`
  && {
    padding: 0 0.5rem 1.5rem;
  }
`;

const StyledStepLabel = styled(StepLabel)`
  && {
    .Mui-active {
      color: ${Colours.OV_BASE};
    }

    .Mui-completed {
      color: ${Colours.OV_GREEN};
    }
  }
`;

const ActionContainer = styled.footer`
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
  gap: 1.5rem;
`;

const StyledOvButton = styled(OvButton)`
  && {
    padding: 0 1rem;
    border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
    transition: none;
    font-weight: bold;
  }
`;

const InfoText = styled.h4`
  font-style: italic;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: ${Colours.TEXT_DARK['500']};
`;
