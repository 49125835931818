import styled from 'styled-components';
import Colours from '../../../design-system/colours';
import { FC, useEffect, useState } from 'react';
import Variables from '../../../design-system/variables';
import { useTranslation } from 'react-i18next';
import OvRemainingCharacters from './OvRemainingCharacters';
import breakpoints from '../../../design-system/breakpoints';

export interface OvTextSurveyQuestionProps {
  value?: string;
  onChange?: (value: string) => void;
}

const OvTextSurveyQuestion: FC<OvTextSurveyQuestionProps> = ({
  onChange,
  value,
}) => {
  const MAX_LENGTH = 2000;
  const { t } = useTranslation();
  const [innerValue, setInnerValue] = useState<string | null>(null);

  useEffect(() => {
    if (value !== innerValue && value) {
      setInnerValue(value);
    }
  }, [value, innerValue]);

  const handleValueChange = (value: string) => {
    setInnerValue(value);
    onChange && onChange(value);
  };

  return (
    <Container>
      <StyledTextarea
        maxLength={MAX_LENGTH}
        placeholder={`${t('survey.question.textPlaceholder')}...`}
        value={innerValue || ''}
        onChange={(event) => handleValueChange(event.target.value)}
      />
      <OvRemainingCharacters
        maxValue={MAX_LENGTH}
        currentValue={innerValue?.length || 0}
      />
    </Container>
  );
};

export default OvTextSurveyQuestion;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

const StyledTextarea = styled.textarea`
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: ${Variables.fontSizes.MEDIUM};
  color: ${Colours.OV_BASE_LIGHT};
  border: 0;
  border-radius: 0.25rem;
  background-color: ${Colours.LIGHT_GRAY_BLUE};
  resize: none;
  max-width: 100%;
  min-height: 7rem;

  @media (min-width: ${breakpoints.sm}) {
    max-width: none;
    width: 26rem;
  }

  &:focus {
    outline: none;
    border-color: ${Colours.OV_YELLOW};
  }

  &::placeholder {
    color: ${Colours.MEDIUM_GRAY_BLUE};
  }
`;
