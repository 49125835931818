import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

export class ErrorUtils {
  static parseAxiosError(action: PayloadAction<AxiosError>): string {
    if (action?.payload?.response?.data) {
      return (
        (action.payload?.response?.data as any)?.error +
        ': ' +
        JSON.stringify(
          ((action.payload?.response?.data as any)?.message as string[])?.join(
            ','
          )
        )
      );
    } else if (action?.payload?.message) {
      return (
        (action.payload as any)?.error +
        ': ' +
        JSON.stringify(action.payload?.message)
      );
    }

    return 'Unknown error';
  }
}
