import React, { FC } from 'react';
import styled from 'styled-components';
import Colours from '../../../design-system/colours';
import DateUtils from '../../../common/utils/services/date-utils';
import { addDays, eachDayOfInterval, isSameDay } from 'date-fns';
import { UserScanningPlan } from '../../../common/model/dto/scan-sequences/user-scanning-plan';
import { useTranslation } from 'react-i18next';
import Variables from '../../../design-system/variables';
import { ScanType } from '../../../common/model/dto/scan-sequences/scan-type.enum';
import { DailyData } from '../../../common/model/dto/daily-data';
import { TryingToConceiveStatus } from '../../../common/model/dto/trying-to-conceive-status.enum';

interface OvUserScanningPlanTableProps {
  userScanningPlan: UserScanningPlan;
  dailyDataArray?: DailyData[];
}

const OvUserScanningPlanTable: FC<OvUserScanningPlanTableProps> = ({
  userScanningPlan,
  dailyDataArray,
}) => {
  const { t } = useTranslation();
  const shouldScanHormone = (scanType: ScanType, numberOfDay?: number) => {
    const scanningPlanEntry =
      userScanningPlan?.scanning_plan_snapshot?.days.find(
        (entry) => entry.day_of_scanning === numberOfDay
      );

    return scanningPlanEntry?.scan_types?.includes(scanType);
  };

  const dates = eachDayOfInterval({
    start: new Date(userScanningPlan.start_day),
    end: new Date(userScanningPlan.end_day),
  });

  const shouldShowScanForDate = (date: Date, scanType: ScanType) => {
    const dailyData = dailyDataArray?.find(
      (dailyData) => dailyData.day === DateUtils.getDbDateTag(date)
    );

    if (scanType === ScanType.LH_PG) {
      return (
        dailyData?.lh !== null &&
        dailyData?.lh !== undefined &&
        dailyData?.pg !== null &&
        dailyData?.pg !== undefined
      );
    }

    return dailyData?.lr_e3g !== null && dailyData?.lr_e3g !== undefined;
  };

  const getDayStatusColor = (dailyData?: DailyData) => {
    if (!dailyData || !dailyData.trying_to_conceive_status) {
      return 'none';
    }

    const tryingToConceiveStatus = dailyData.trying_to_conceive_status;

    switch (tryingToConceiveStatus) {
      case TryingToConceiveStatus.LOW:
        return Colours.PALE_LIME;
      case TryingToConceiveStatus.FERTILE:
        return Colours.OV_FERTILE;
      case TryingToConceiveStatus.HIGH:
        return Colours.OV_HIGH_TRYING_TO_CONCEIVE;
      case TryingToConceiveStatus.PEAK:
        return Colours.OV_HIGH_TRYING_TO_CONCEIVE;
      case TryingToConceiveStatus.POST_OVULATION:
        return Colours.POST_OVULATION;
      default:
        return 'transparent';
    }
  };

  const isValidScanStatusForTable = (date: Date) => {
    const dailyData = dailyDataArray?.find(
      (dailyData) => dailyData.day === DateUtils.getDbDateTag(date)
    );

    return (
      dailyData?.trying_to_conceive_status &&
      dailyData?.trying_to_conceive_status !== 'period'
    );
  };

  return (
    <TableContainer>
      <StyledTable>
        <thead>
          <tr>
            <StyledLabelCellHeader />
            {userScanningPlan?.scanning_plan_snapshot?.days.map((entry) => (
              <StyledDayHeader
                key={DateUtils.getDbDateTag(
                  addDays(
                    new Date(userScanningPlan.start_day),
                    entry?.day_of_scanning ?? 0
                  )
                )}
              >
                <StyledMonthName>
                  {addDays(
                    new Date(userScanningPlan.start_day),
                    entry?.day_of_scanning ?? 0
                  ).toLocaleString('default', {
                    month: 'short',
                  })}
                </StyledMonthName>
                {addDays(
                  new Date(userScanningPlan.start_day),
                  entry?.day_of_scanning ?? 0
                ).getDate()}
              </StyledDayHeader>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr key={'lh-pg'}>
            <StyledLabelCell>
              {t('userScanningPlan.lhPgScanDays')}
            </StyledLabelCell>
            {userScanningPlan.scanning_plan_snapshot?.days.map(
              (entry, dayIndex) => (
                <StyledDayCell
                  key={dayIndex}
                  style={{
                    backgroundColor:
                      userScanningPlan?.cancel_day &&
                      isSameDay(
                        new Date(userScanningPlan?.cancel_day),
                        new Date(entry.day)
                      )
                        ? 'rgba(255, 0, 0, 0.6)'
                        : 'transparent',
                  }}
                >
                  {shouldScanHormone(ScanType.LH_PG, entry.day_of_scanning) && (
                    <StyledLhPgCellContent />
                  )}
                </StyledDayCell>
              )
            )}
          </tr>
          <tr key={'lr-e3g'}>
            <StyledLabelCell>
              {t('userScanningPlan.lre3gScanDays')}
            </StyledLabelCell>
            {userScanningPlan.scanning_plan_snapshot?.days.map(
              (entry, dayIndex) => (
                <StyledDayCell
                  key={dayIndex}
                  style={{
                    backgroundColor:
                      userScanningPlan?.cancel_day &&
                      isSameDay(
                        new Date(userScanningPlan?.cancel_day),
                        new Date(entry.day)
                      )
                        ? 'rgba(255, 0, 0, 0.6)'
                        : 'transparent',
                  }}
                >
                  {shouldScanHormone(
                    ScanType.LR_E3G,
                    entry.day_of_scanning
                  ) && <StyledE3gCellContent />}
                </StyledDayCell>
              )
            )}
          </tr>
          <tr key={'lh-pg-scans'}>
            <StyledLabelCell>{t('userScanningPlan.lhPgScans')}</StyledLabelCell>
            {dates.map((date, dateIndex) => (
              <StyledDayCell
                key={dateIndex}
                style={{
                  backgroundColor:
                    userScanningPlan?.cancel_day &&
                    isSameDay(new Date(userScanningPlan?.cancel_day), date)
                      ? 'rgba(255, 0, 0, 0.6)'
                      : 'transparent',
                }}
              >
                {shouldShowScanForDate(date, ScanType.LH_PG) && (
                  <StyledDayCellContent
                    style={{
                      backgroundColor: getDayStatusColor(
                        dailyDataArray?.find(
                          (dailyData) =>
                            dailyData.day === DateUtils.getDbDateTag(date)
                        )
                      ),
                      border: isValidScanStatusForTable(date)
                        ? `3px solid ${Colours.OV_BASE}`
                        : 'none',
                    }}
                  />
                )}
              </StyledDayCell>
            ))}
          </tr>
          <tr key={'lr-e3g-scans'}>
            <StyledLabelCell>
              {t('userScanningPlan.lrE3gScans')}
            </StyledLabelCell>
            {dates.map((date, dateIndex) => (
              <StyledDayCell
                key={dateIndex}
                style={{
                  backgroundColor:
                    userScanningPlan?.cancel_day &&
                    isSameDay(new Date(userScanningPlan?.cancel_day), date)
                      ? 'rgba(255, 0, 0, 0.6)'
                      : 'transparent',
                }}
              >
                {shouldShowScanForDate(date, ScanType.LR_E3G) && (
                  <StyledDayCellContent
                    style={{
                      backgroundColor: getDayStatusColor(
                        dailyDataArray?.find(
                          (dailyData) =>
                            dailyData.day === DateUtils.getDbDateTag(date)
                        )
                      ),
                      border: isValidScanStatusForTable(date)
                        ? `3px solid ${Colours.OV_BASE}`
                        : 'none',
                    }}
                  />
                )}
              </StyledDayCell>
            ))}
          </tr>
        </tbody>
      </StyledTable>
    </TableContainer>
  );
};

export default OvUserScanningPlanTable;

const StyledTable = styled.table`
  width: auto;
  border-collapse: collapse;
  border: 1px solid #ccc;
`;

const TableContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  position: relative;
  margin: 0 auto;
  display: block;
  padding: 1rem 0;
`;

const StyledLabelCellHeader = styled.th`
  font-weight: lighter;
  font-size: 0.65rem;
  text-align: left;
  width: 1.875rem;
  position: sticky;
  z-index: 1;
  left: -0.125rem;
  background-color: ${Colours.OV_WHITE};
  border-bottom: 1px solid #ccc;
`;

const StyledDayHeader = styled.th`
  background-color: 'none';
  text-align: center;
  font-size: 0.85rem;
  width: 2rem;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  height: 3rem;
`;

const StyledDayCell = styled.td`
  background-color: 'none';
  text-align: center;
  width: 2rem;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.4rem;
  white-space: initial;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  height: 2rem;
`;

const StyledMonthName = styled.div`
  padding: 0;
  margin: 0;
  font-weight: normal;
  font-size: 0.5rem;
`;

const StyledLabelCell = styled.td`
  font-weight: lighter;
  font-size: 0.65rem;
  text-align: left;
  padding: 0.5rem 0.5rem;
  position: sticky;
  z-index: 1;
  left: -0.125rem;
  background-color: ${Colours.OV_WHITE};
  height: 1rem;
  border-bottom: 1px solid #ccc;
  white-space: nowrap;
`;

const StyledLhPgCellContent = styled.div`
  margin: auto;
  width: 1rem;
  height: 1rem;
  border-radius: ${Variables.borderRadius.CIRCLE};
  background-image: linear-gradient(
    to right,
    ${Colours.LIGHT_LEMON} 42%,
    ${Colours.OV_BASE} 42%,
    ${Colours.OV_BASE} 58%,
    ${Colours.BRIGHT_YELLOW} 58%
  );
  background-size: 100% 100%;
  border: 0.2rem solid ${Colours.OV_BASE};
`;

const StyledE3gCellContent = styled.div`
  margin: auto;
  width: 1rem;
  height: 1rem;
  border-radius: ${Variables.borderRadius.CIRCLE};
  background-color: ${Colours.OV_LIGHT_GRAY};
  border: 0.2rem solid ${Colours.OV_BASE};
`;

const StyledDayCellContent = styled.div`
  width: 1rem;
  height: 1rem;
  border-radius: ${Variables.borderRadius.CIRCLE};
  margin: auto;
`;
