import React, { FC, useState } from 'react';
import styled from 'styled-components';
import Colours from '../../../design-system/colours';
import Variables from '../../../design-system/variables';
import { useTranslation } from 'react-i18next';
import {
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
} from '@mui/material';
import OvSelect from '../atoms/OvSelect';
import { ScheduleValueType } from '../../../common/model/type/schedule-value.type';
import OvCompactButton from '../atoms/OvCompactButton';
import OvConfirmationDialog from '../molecules/OvConfirmationDialog';
import { Survey } from '../../../common/model/dto/survey/survey';
import CreateIcon from '@mui/icons-material/Create';
import PreviewIcon from '@mui/icons-material/Preview';
import OvBottomActionDrawer from '../molecules/OvBottomActionDrawer';
import OvSurvey from './OvSurvey';
import { NavLink } from 'react-router-dom';
import { NpsSurveySettings } from '../../../common/model/dto/survey/nps-survey-settings';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import OvLoadingIndicator from '../atoms/OvLoadingIndicator';
import { updateNpsSurveySettings } from '../../../redux/thunks/admin/admin-nps-survey.thunk';

const OvNpsSurveySetupTab: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const npsSurvey: Survey = useAppSelector(
    (state) => state.adminNpsSurvey.currentNpsSurvey
  );
  const surveySettings: NpsSurveySettings = useAppSelector(
    (state) => state.adminNpsSurvey.npsSurveySettings
  );
  const isLoading: boolean = useAppSelector(
    (state) => state.adminNpsSurvey.loading
  );

  const [isOpenConfirmationDialog, setOpenConfirmationDialog] =
    useState<boolean>(false);
  const [isOpenNpsSurveyPreview, setOpenNpsSurveyPreview] =
    useState<boolean>(false);

  const scheduledOptions = [
    {
      value: ScheduleValueType.QUARTERLY,
      label: t('survey.frequencyOptions.scheduled.options.quarterly'),
    },
    {
      value: ScheduleValueType.SEMI_ANNUALLY,
      label: t('survey.frequencyOptions.scheduled.options.semiAnnual'),
    },
    {
      value: ScheduleValueType.ANNUALLY,
      label: t('survey.frequencyOptions.scheduled.options.annually'),
    },
  ];

  const handleIsScheduledChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = (event.target as HTMLInputElement).value === 'true';

    dispatch(
      updateNpsSurveySettings({
        ...surveySettings,
        scheduled_type: value
          ? ScheduleValueType.QUARTERLY
          : ScheduleValueType.NONE,
      })
    );
  };

  const handleScheduledValueChange = (event: any, newValue?: any) => {
    const scheduled_type = event?.target ? event.target.value : newValue;
    dispatch(updateNpsSurveySettings({ ...surveySettings, scheduled_type }));
  };

  return (
    <>
      {surveySettings && (
        <>
          <SurveyQuestionSection>
            <SectionLabel>{t('survey.questions')}</SectionLabel>

            {npsSurvey ? (
              <CtaContainer>
                <OvCompactButton
                  onClick={() => setOpenNpsSurveyPreview(true)}
                  icon={<PreviewIcon />}
                  disabled={!surveySettings?.enabled}
                >
                  {t('survey.preview')}
                </OvCompactButton>

                <StyledNavLink to={`/surveys/nps/builder?id=${npsSurvey.id}`}>
                  <OvCompactButton
                    icon={<CreateIcon />}
                    disabled={!surveySettings?.enabled}
                  >
                    {t('survey.updateSurvey')}
                  </OvCompactButton>
                </StyledNavLink>
              </CtaContainer>
            ) : (
              <>
                <Description>{t('survey.noNpsCreatedYet')}</Description>
                <CtaContainer>
                  <StyledNavLink to={`/surveys/nps/builder`}>
                    <OvCompactButton
                      icon={<CreateIcon />}
                      disabled={!surveySettings?.enabled}
                    >
                      {t('survey.create')}
                    </OvCompactButton>
                  </StyledNavLink>
                </CtaContainer>
              </>
            )}
          </SurveyQuestionSection>

          <SectionLabel>{t('survey.frequency')}</SectionLabel>

          <StyledFormControl>
            <RadioGroup
              aria-labelledby={t('survey.frequency')}
              value={surveySettings?.scheduled_type !== ScheduleValueType.NONE}
              onChange={handleIsScheduledChange}
            >
              <FrequencyOptionGroup>
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label={t('survey.frequencyOptions.scheduled.title')}
                  disabled={!surveySettings?.enabled || !npsSurvey || isLoading}
                />

                <FrequencyDescription>
                  {t('survey.frequencyOptions.scheduled.description')}
                </FrequencyDescription>

                <StyledOvSelect
                  disabled={
                    isLoading ||
                    surveySettings?.scheduled_type === ScheduleValueType.NONE ||
                    !surveySettings?.enabled ||
                    !npsSurvey
                  }
                  value={surveySettings?.scheduled_type}
                  onChange={handleScheduledValueChange}
                  placeholder={t(
                    'survey.frequencyOptions.scheduled.placeholder'
                  )}
                >
                  {scheduledOptions.map((option) => (
                    <StyledMenuItem key={option.value} value={option.value}>
                      {option.label}
                    </StyledMenuItem>
                  ))}
                </StyledOvSelect>
              </FrequencyOptionGroup>

              <FrequencyOptionGroup>
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label={t('survey.frequencyOptions.manual.title')}
                  disabled={!surveySettings?.enabled || !npsSurvey || isLoading}
                />

                <FrequencyDescription>
                  {t('survey.frequencyOptions.manual.description')}
                </FrequencyDescription>
              </FrequencyOptionGroup>
            </RadioGroup>
          </StyledFormControl>

          <SectionLabel>
            {t('survey.frequencyOptions.manual.send.title')}
          </SectionLabel>

          <Description>
            {t('survey.frequencyOptions.manual.send.description')}
          </Description>

          <SendSurveyCta
            onClick={() => setOpenConfirmationDialog(true)}
            disabled={!surveySettings?.enabled || !npsSurvey}
          >
            {t('survey.frequencyOptions.manual.send.cta')}
          </SendSurveyCta>

          <OvConfirmationDialog
            isOpen={isOpenConfirmationDialog}
            onCancel={() => setOpenConfirmationDialog(false)}
            title={`${t('survey.frequencyOptions.manual.send.cta')}?`}
            description={t(
              surveySettings?.scheduled_type !== ScheduleValueType.NONE
                ? 'survey.frequencyOptions.manual.confirmation.descWhenScheduledActive'
                : 'survey.frequencyOptions.manual.confirmation.descWhenManual'
            )}
          />

          {npsSurvey && (
            <OvBottomActionDrawer
              canClose
              showBackdropShadow
              isOpen={isOpenNpsSurveyPreview}
              onClose={() => setOpenNpsSurveyPreview(false)}
            >
              <OvSurvey survey={npsSurvey} isPreviewMode />
            </OvBottomActionDrawer>
          )}
        </>
      )}
      {isLoading && <OvLoadingIndicator />}
    </>
  );
};

export default OvNpsSurveySetupTab;

const SectionLabel = styled.h4`
  color: ${Colours.OV_BASE};
  font-size: ${Variables.fontSizes.LARGE};
  margin-bottom: 0.25rem;
  margin-top: 0;
`;

const StyledFormControl = styled(FormControl)`
  && {
    border-bottom: 1px solid ${Colours.OV_LIGHT_GRAY};
    padding-bottom: 1.25rem;
    margin-bottom: 1.25rem;
    width: 100%;
  }
`;

const FrequencyOptionGroup = styled.div`
  margin-bottom: 0.5rem;
`;

const Description = styled.p`
  color: ${Colours.OV_BASE};
  font-size: ${Variables.fontSizes.MEDIUM};
  margin: 0;
`;

const FrequencyDescription = styled(Description)`
  margin-left: 2rem;
`;

const StyledOvSelect = styled(OvSelect)`
  && {
    margin-top: 0.75rem;
    margin-left: 2rem;
    min-width: 15rem;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  && {
    &.MuiButtonBase-root {
      display: flex;
      justify-content: flex-start;
      padding: 0.375rem 1rem;
      font-size: ${Variables.fontSizes.MEDIUM};
    }
  }
`;

const SendSurveyCta = styled(OvCompactButton)`
  && {
    margin-top: 1rem;
    padding: 0.25rem 0.5rem;
  }
`;

const SurveyQuestionSection = styled.div`
  margin-bottom: 1.25rem;
  padding-bottom: 1.25rem;
  border-bottom: 1px solid ${Colours.OV_LIGHT_GRAY};
`;

const CtaContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1.25rem;
  gap: 1.5rem;
`;

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
`;
