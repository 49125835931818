import axios from 'axios';
import { ADMIN_NPS_SURVEY_ROUTE } from '../api/backend';
import { NpsSurveySettings } from '../common/model/dto/survey/nps-survey-settings';
import { Survey } from '../common/model/dto/survey/survey';

export class NpsSurveyService {
  static async getCurrentNpsSurvey(): Promise<Survey> {
    const { data } = await axios.get<Survey>(`${ADMIN_NPS_SURVEY_ROUTE}`);

    return data;
  }

  static async getNpsSurveyById(id: string): Promise<Survey> {
    const { data } = await axios.get<Survey>(`${ADMIN_NPS_SURVEY_ROUTE}/${id}`);

    return data;
  }

  static async createNpsSurvey(npsSurvey: Survey): Promise<Survey> {
    const { data } = await axios.post<Survey>(
      `${ADMIN_NPS_SURVEY_ROUTE}`,
      npsSurvey
    );

    return data;
  }

  static async updateNpsSurvey(npsSurvey: Survey): Promise<Survey> {
    const { data } = await axios.patch<Survey>(
      `${ADMIN_NPS_SURVEY_ROUTE}/${npsSurvey.id}`,
      npsSurvey
    );

    return data;
  }

  static async getNpsSurveySettings(): Promise<NpsSurveySettings> {
    const { data } = await axios.get<NpsSurveySettings>(
      `${ADMIN_NPS_SURVEY_ROUTE}/settings`
    );

    return data;
  }

  static async updateNpsSurveySettings(
    npsSurveySettings: NpsSurveySettings
  ): Promise<NpsSurveySettings> {
    const { data } = await axios.patch<NpsSurveySettings>(
      `${ADMIN_NPS_SURVEY_ROUTE}/settings`,
      npsSurveySettings
    );

    return data;
  }
}
