import React, { FC } from 'react';
import styled from 'styled-components';
import Variables from '../../../design-system/variables';
import Colours from '../../../design-system/colours';
import { useTranslation } from 'react-i18next';

interface OvRemainingCharactersProps {
  maxValue: number;
  currentValue: number;
}

const OvRemainingCharacters: FC<OvRemainingCharactersProps> = ({
  maxValue,
  currentValue,
}) => {
  const { t } = useTranslation();
  const remainingCharacters = maxValue - currentValue;

  return (
    <Container>
      <RemainingText>
        {remainingCharacters > 1
          ? t('common.remainingCharactersPlural', {
              count: remainingCharacters,
            })
          : t('common.remainingCharacter', { count: remainingCharacters })}
      </RemainingText>
    </Container>
  );
};

export default OvRemainingCharacters;

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

const RemainingText = styled.span`
  font-size: ${Variables.fontSizes.SMALL};
  color: ${Colours.OV_BASE_LIGHT};
`;
