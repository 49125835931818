import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import NpsSurvey from './NpsSurvey';

const Surveys: React.FC = () => {
  let { url } = useRouteMatch();

  return (
    <Switch>
      {/*  TODO add custom surveys and implement selection between nps and custom surveys when it will be requested*/}
      <Route exact path={`${url}`}>
        <Redirect to={`${url}/nps`} />
      </Route>
      <Route path={`${url}/nps`} component={NpsSurvey} />
    </Switch>
  );
};

export default Surveys;
