import React, { FC } from 'react';
import { Survey } from '../../../common/model/dto/survey/survey';
import styled from 'styled-components';
import Colours from '../../../design-system/colours';
import Variables from '../../../design-system/variables';
import { useTranslation } from 'react-i18next';
import { Field, FieldProps, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { object } from 'yup';
import { PlatformTypeEnum } from '../../../common/model/type/platform-type.enum';
import OvFormObserver from '../atoms/OvFormObserver';
import OvTextFieldFormControl from '../atoms/OvTextFieldFormControl';
import OvQuillEditor from '../atoms/OvQuillEditor';
import { Checkbox, MenuItem, SelectChangeEvent } from '@mui/material';
import OvSelect from '../atoms/OvSelect';
import { SurveyType } from '../../../common/model/type/survey-type.enum';
import breakpoints from '../../../design-system/breakpoints';

export interface OvSurveyBuilderSettingsProps {
  survey?: Partial<Survey>;
  surveyType?: SurveyType;
  onChange: (values: Partial<Survey>, isValid?: boolean) => void;
}

const OvSurveyBuilderSettings: FC<OvSurveyBuilderSettingsProps> = ({
  survey,
  surveyType,
  onChange,
}) => {
  const { t } = useTranslation();

  const onPlatformChange = async (
    event: SelectChangeEvent<unknown>,
    { field, form }: FieldProps
  ) => {
    const {
      target: { value },
    } = event;
    const index = field.value.indexOf(value);

    await form.setFieldValue(
      field.name,
      index === -1
        ? [...field.value, value]
        : (field.value as string[]).filter((item) => item !== value)
    );
  };

  return (
    <Wrapper>
      <Formik
        initialValues={{
          title: survey?.title || '',
          description: survey?.description || '',
          platform: survey?.platform || [],
          submit_screen: {
            title: survey?.submit_screen?.title || '',
            description: survey?.submit_screen?.description || '',
          },
        }}
        validationSchema={Yup.object().shape({
          title: Yup.string().required(t('common.requiredField')),
          description: Yup.string(),
          platform: Yup.array().of(Yup.string()),
          submit_screen: object().shape({
            title: Yup.string().required(t('common.requiredField')),
            description: Yup.string(),
          }),
        })}
        enableReinitialize={true}
        onSubmit={(values) => console.log(values)}
        validateOnBlur
      >
        <Form>
          <StyledFormLabel>
            <StyledLabelText>{t('survey.title')}</StyledLabelText>

            <Field
              component={StyledTextField}
              type="text"
              name="title"
              placeholder={t('survey.title')}
            />
          </StyledFormLabel>

          <StyledFormLabel>
            <StyledLabelText>{t('survey.description')}</StyledLabelText>

            <Field
              component={StyledTextField}
              type="text"
              name="description"
              placeholder={t('survey.description')}
            />
          </StyledFormLabel>

          <StyledFormLabel>
            <StyledLabelText>{t('survey.platformType')}</StyledLabelText>

            <Field name="platform">
              {(fieldProps: FieldProps) => (
                <StyledSelectField
                  value={fieldProps.field.value}
                  renderValue={(selectedPlatforms) =>
                    selectedPlatforms
                      ? (selectedPlatforms as PlatformTypeEnum[])
                          .map((platform: any) => t(`platformType.${platform}`))
                          .join(', ')
                      : ''
                  }
                  onChange={(event) => onPlatformChange(event, fieldProps)}
                >
                  {Object.values(PlatformTypeEnum).map((platform) => (
                    <StyledMenuItem key={platform} value={platform}>
                      {t(`platformType.${platform}`)}

                      <Checkbox
                        checked={fieldProps.field.value?.indexOf(platform) > -1}
                      />
                    </StyledMenuItem>
                  ))}
                </StyledSelectField>
              )}
            </Field>
          </StyledFormLabel>

          <StyledFormLabel>
            <StyledLabelText>{t('survey.submitScreen.title')}</StyledLabelText>

            <Field
              component={StyledTextField}
              type="text"
              name="submit_screen.title"
              placeholder={t('survey.submitScreen.titlePlaceholder')}
            />
          </StyledFormLabel>

          <StyledFormLabel>
            <StyledLabelText>
              {t('survey.submitScreen.description')}
            </StyledLabelText>

            <Field type="text" name="submit_screen.description">
              {({ field }: FieldProps) => (
                <OvQuillEditor
                  value={field.value}
                  handleChange={field.onChange(field.name)}
                />
              )}
            </Field>
          </StyledFormLabel>

          <OvFormObserver valuesChanged={onChange} />
        </Form>
      </Formik>
    </Wrapper>
  );
};

export default OvSurveyBuilderSettings;

const Wrapper = styled.div`
  width: 100%;

  @media (min-width: ${breakpoints.md}) {
    max-width: 36rem;
  }
`;

const StyledFormLabel = styled.label`
  display: block;
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }
`;

const StyledLabelText = styled.p`
  font-size: ${Variables.fontSizes.MEDIUM};
  font-weight: bold;
  text-indent: 0.5rem;
  margin-bottom: 0.5rem;
  margin-top: 0;
`;

const StyledTextField = styled(OvTextFieldFormControl)`
  && {
    .MuiFormHelperText-root.Mui-error {
      background: ${Colours.OV_WHITE};
      margin: 0;
      padding: 0.25rem 0.75rem;
    }

    &.MuiFormControl-root {
      width: 100%;

      .MuiInputBase-root {
        width: 100%;
      }
    }
  }
`;

const StyledSelectField = styled(OvSelect)`
  && {
    width: 100%;
    padding: 0.75rem 0.875rem;
    margin-bottom: 0.75rem;

    .MuiSelect-select {
      padding: 0;
    }

    &.Mui-disabled {
      border: inherit;
    }
  }
`;

const StyledMenuItem = styled(MenuItem)`
  && {
    &.MuiButtonBase-root {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.375rem 1rem;
      font-size: ${Variables.fontSizes.MEDIUM};
    }
  }
`;
