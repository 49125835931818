import React, { FC } from 'react';
import { UserInfo } from '../../../common/model/dto/user-info';
import usePaginationQueryParams from '../../../hooks/use-pagination-query-params';
import useAxios from 'axios-hooks';
import { Paginated } from '../../../common/types';
import { UserScanningPlan } from '../../../common/model/dto/scan-sequences/user-scanning-plan';
import {
  ADMIN_DAILY_DATA_ROUTE,
  ADMIN_USER_SCANNING_PLANS,
} from '../../../api/backend';
import styled from 'styled-components';
import OvPagination from '../atoms/OvPagination';
import { PaginationItem } from '@mui/material';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import OvLoadingIndicator from '../atoms/OvLoadingIndicator';
import Colours from '../../../design-system/colours';
import OvUserScanningPlanList from './OvUserScanningPlanList';
import OvNoContent from '../molecules/OvNoContent';
import { useTranslation } from 'react-i18next';
import { DailyData } from '../../../common/model/dto/daily-data';

export const SCANNING_PLAN_LIST_LIMIT = 15;

const OvUserScanningPlans: FC<{ selectedUser?: UserInfo }> = ({
  selectedUser,
}) => {
  const { t } = useTranslation();

  const { offset, setOffset } = usePaginationQueryParams();

  const [{ data, loading, error }] = useAxios<Paginated<UserScanningPlan>>(
    {
      url: `/${ADMIN_USER_SCANNING_PLANS}/${selectedUser?.id}`,
      method: 'get',
      params: {
        limit: SCANNING_PLAN_LIST_LIMIT,
        offset,
        order_by: 'created_at',
        order_dir: 'desc',
      },
    },
    { useCache: false }
  );

  const userScanningPlans = data?.docs ?? [];
  const total = data?.total ?? 0;

  const { earliestStart, latestEnd } = userScanningPlans?.reduce(
    (acc, { start_day, end_day }) => {
      if (start_day < acc.earliestStart) acc.earliestStart = start_day;
      if (end_day > acc.latestEnd) acc.latestEnd = end_day;
      return acc;
    },
    {
      earliestStart: userScanningPlans[0]?.start_day,
      latestEnd: userScanningPlans[0]?.end_day,
    }
  );

  const [{ data: dailyDataList, loading: dailyDataLoading }] = useAxios<
    Paginated<DailyData>
  >(
    {
      url: `${ADMIN_DAILY_DATA_ROUTE}`,
      method: 'get',
      params: {
        user_document_id: selectedUser?.document_id,
        day_from: earliestStart,
        day_to: latestEnd,
        limit: 0,
      },
    },
    { useCache: false, manual: !!selectedUser && !data }
  );

  const goToPage = (_: any, value: number) => {
    setOffset((value - 1) * SCANNING_PLAN_LIST_LIMIT);
  };

  const pageCount = () => Math.ceil(total / SCANNING_PLAN_LIST_LIMIT);

  return (
    <Container>
      <CenterPane>
        <Actions>
          {total > SCANNING_PLAN_LIST_LIMIT && (
            <OvPagination
              page={(offset ?? 0) / SCANNING_PLAN_LIST_LIMIT + 1}
              onChange={goToPage}
              count={pageCount()}
              renderItem={(item) => (
                <PaginationItem
                  components={{ previous: ArrowBack, next: ArrowForward }}
                  {...item}
                />
              )}
              shape="rounded"
            />
          )}
        </Actions>
        {error && <StyledErrorMessage>{error.message}</StyledErrorMessage>}

        {userScanningPlans.length > 0 && !loading && !dailyDataLoading ? (
          <OvUserScanningPlanList
            userScanningPlans={userScanningPlans}
            dailyData={dailyDataList?.docs}
          />
        ) : (
          !loading &&
          !dailyDataLoading && (
            <OvNoContent>{t('userScanningPlan.emptyResultsList')}</OvNoContent>
          )
        )}

        {(loading || dailyDataLoading) && (
          <OvLoadingIndicator position="fixed" />
        )}
      </CenterPane>
    </Container>
  );
};

export default OvUserScanningPlans;

const Container = styled.section`
  display: flex;
`;

const CenterPane = styled.div`
  flex-grow: 1;
  margin: 0 auto;
  padding: 0 1rem;
`;

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
`;

const StyledErrorMessage = styled.p`
  color: ${Colours.OV_RED};
  margin: 10px 0 24px 0;
  display: flex;
  align-items: center;
`;
