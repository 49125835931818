import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import Colours from '../../../design-system/colours';
import Variables from '../../../design-system/variables';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';

export interface OvBottomActionDrawerProps {
  canClose?: boolean;
  isOpen?: boolean;
  onClose?: () => void;
  showBackdropShadow?: boolean;
  children?: ReactNode;
}

const OvBottomActionDrawer: FC<OvBottomActionDrawerProps> = ({
  canClose,
  isOpen,
  onClose,
  showBackdropShadow,
  children,
}) => {
  let actionDrawer = (
    <Container>
      <InnerContainer>
        {children}

        {canClose && (
          <StyledCloseIconButton onClick={onClose}>
            <CloseIcon />
          </StyledCloseIconButton>
        )}
      </InnerContainer>
    </Container>
  );

  if (showBackdropShadow) {
    actionDrawer = (
      <ContainerWithBackdrop>
        <InnerContainerWithBackdrop>
          {children}

          {canClose && (
            <StyledCloseIconButton onClick={onClose}>
              <CloseIcon />
            </StyledCloseIconButton>
          )}
        </InnerContainerWithBackdrop>
      </ContainerWithBackdrop>
    );
  }

  return <>{isOpen && actionDrawer}</>;
};

export default OvBottomActionDrawer;

const Container = styled.section`
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  background: ${Colours.WHITE};
  box-shadow: ${Variables.boxShadow.dropShadow};
`;

const ContainerWithBackdrop = styled(Container)`
  background: rgba(0, 0, 0, 0.6);
  top: 0;
  height: 100vh;
`;

const InnerContainer = styled.div`
  padding: 1.875rem;
  position: relative;
`;

const InnerContainerWithBackdrop = styled(InnerContainer)`
  position: absolute;
  bottom: 0;
  width: 100%;
  background: ${Colours.WHITE};
`;

const StyledCloseIconButton = styled(IconButton)`
  && {
    position: absolute;
    top: 0.75rem;
    right: 0.75rem;
    padding: 0;
    margin: 0;
    vertical-align: bottom;

    svg {
      height: 1rem;
      width: 1rem;
      color: #6c6e70;
    }

    &:hover {
      background: none;
    }

    .MuiTouchRipple-root {
      display: none !important;
    }
  }
`;
