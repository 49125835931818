import React, { FC } from 'react';
import styled from 'styled-components';
import Colours from '../../../design-system/colours';
import Variables from '../../../design-system/variables';

export interface OvSurveySubmitScreenProps {
  title: string;
  description?: string;
}

const OvSurveySubmitScreen: FC<OvSurveySubmitScreenProps> = ({
  title,
  description,
}) => {
  return (
    <Container>
      <Title>{title}</Title>
      {description && (
        <Description
          dangerouslySetInnerHTML={{ __html: description }}
        ></Description>
      )}
    </Container>
  );
};

export default OvSurveySubmitScreen;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const BaseTextStyle = `
  margin: 0;
  color: ${Colours.OV_BASE_LIGHT};
  font-weight: 400;
`;

const Title = styled.h3`
  ${BaseTextStyle};
  font-size: ${Variables.fontSizes.MLARGE};
  line-height: ${Variables.lineHeights.MLARGE};
`;

const Description = styled.p`
  ${BaseTextStyle};
  margin-top: 1rem;
  font-size: ${Variables.fontSizes.LARGE};
  line-height: ${Variables.lineHeights.LARGE};
`;
