import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import * as qs from 'query-string';
import { ParsedQuery } from 'query-string';
import OvSurveyBuilder from '../UI/organisms/OvSurveyBuilder';
import { SurveyType } from '../../common/model/type/survey-type.enum';
import Variables from '../../design-system/variables';
import Colours from '../../design-system/colours';
import OvPageTitle from '../UI/molecules/OvPageTitle';
import PollIcon from '@mui/icons-material/Poll';
import { Survey } from '../../common/model/dto/survey/survey';
import OvBackButton from '../UI/atoms/OvBackButton';
import {
  createNpsSurvey,
  getNpsSurveyById,
  updateNpsSurvey,
} from '../../redux/thunks/admin/admin-nps-survey.thunk';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { History } from 'history';
import { useHistory } from 'react-router-dom';
import OvErrorMessage from '../UI/atoms/OvErrorMessage';

const NpsSurveyBuilder: FC = () => {
  const { t } = useTranslation();
  const { id }: ParsedQuery = qs.parse(window.location.search);
  const history: History = useHistory();
  const dispatch = useAppDispatch();
  const currentNpsSurvey: Survey = useAppSelector(
    (state) => state.adminNpsSurvey.currentNpsSurvey
  );
  const errorMessage = useAppSelector(
    (state) => state.adminNpsSurvey.builderError
  );
  const [survey, setSurvey] = useState<Survey>();

  useEffect(() => {
    if (id) {
      if (currentNpsSurvey?.id === id) {
        setSurvey(currentNpsSurvey);
      } else {
        dispatch(getNpsSurveyById(id as string));
      }
    }
  }, [id, dispatch, currentNpsSurvey]);

  const handleNpsSurveySave = (npsSurvey: Survey) => {
    if (id) {
      dispatch(
        updateNpsSurvey({
          npsSurvey: {
            ...npsSurvey,
            id: id as string,
          },
          history,
        })
      );
    } else {
      dispatch(createNpsSurvey({ npsSurvey, history }));
    }
  };

  const handleSurveyChange = (values: Partial<Survey>) => {
    setSurvey({ ...survey, ...values });
  };

  return (
    <Container>
      <Header>
        <OvBackButton label={t('common.actions.back')} />
        <OvPageTitle
          title={t(id ? 'survey.updateSurvey' : 'survey.create')}
          icon={<StyledPollIcon />}
        />
      </Header>

      <BuilderWrapper>
        <OvSurveyBuilder
          survey={survey}
          surveyType={SurveyType.NPS}
          onChange={handleSurveyChange}
          onSave={handleNpsSurveySave}
        />
      </BuilderWrapper>

      {errorMessage && (
        <ErrorMessageContainer>
          <OvErrorMessage message={errorMessage} />
        </ErrorMessageContainer>
      )}
    </Container>
  );
};

export default NpsSurveyBuilder;

const Container = styled.div`
  margin-left: 1rem;
  box-shadow: ${Variables.boxShadow.defaultBox};
  background-color: ${Colours.WHITE};
  padding: 1.5rem;
  border-radius: ${Variables.borderRadius.LARGE};
`;

const Header = styled.header`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  flex-wrap: wrap;
`;

const StyledPollIcon = styled(PollIcon)`
  && {
    margin-right: 0.5rem;
  }
`;

const BuilderWrapper = styled.div`
  margin-top: 1.5rem;
`;

const ErrorMessageContainer = styled.div`
  margin-top: 1rem;
`;
