import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  getLatestConfiguration,
  updateConfiguration,
} from '../../redux/thunks/admin/admin-configuration.thunk';
import styled from 'styled-components';
import Variables from '../../design-system/variables';
import Colours from '../../design-system/colours';
import JSONInput from 'react-json-editor-ajrm';
// @ts-ignore
import locale from 'react-json-editor-ajrm/locale/en';
import OvCompactButton from '../UI/atoms/OvCompactButton';
import { Grid, Snackbar, Typography } from '@mui/material';
import OvLoadingIndicator from '../UI/atoms/OvLoadingIndicator';
import OvErrorMessage from '../UI/atoms/OvErrorMessage';
import UpdateIcon from '@mui/icons-material/Update';
import { toolkitErrorCodeDescriptions } from '../../common/model/dto/lot';

const ConfigurationErrorMessages = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const isLoading = useAppSelector((state) => state.adminConfiguration.loading);
  const errorMessage = useAppSelector(
    (state) => state.adminConfiguration.errorMessage
  );
  const latestConfiguration = useAppSelector(
    (state) => state.adminConfiguration.latestConfiguration
  );

  const [updatedJson, setUpdatedJson] = useState<any>({});
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);

  useEffect(() => {
    dispatch(getLatestConfiguration());
  }, [dispatch]);

  useEffect(() => {
    if (latestConfiguration?.error_messages) {
      setUpdatedJson(latestConfiguration.error_messages);
    }
  }, [latestConfiguration]);

  useEffect(() => {
    if (errorMessage) {
      setOpenErrorSnackbar(true);
    }
  }, [errorMessage]);

  const handleJsonChange = (value: any) => {
    setUpdatedJson(value.jsObject);
  };

  const handleUpdateConfiguration = () => {
    if (latestConfiguration) {
      dispatch(
        updateConfiguration({
          ...latestConfiguration,
          error_messages: updatedJson,
        })
      );
    }
  };

  const handleCloseSnackbar = () => {
    setOpenErrorSnackbar(false);
  };

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <JsonContainer>
            {latestConfiguration && (
              <JSONInput
                id="error_messages_editor"
                placeholder={latestConfiguration.error_messages}
                locale={locale}
                theme="light_mitsuketa_tribute"
                height="550px"
                onChange={handleJsonChange}
              />
            )}
          </JsonContainer>
        </Grid>
        <Grid item xs={12} md={6}>
          <InfoContainer>
            <Typography variant="h6" gutterBottom>
              Toolkit error/warning code descriptions
            </Typography>
            <ParameterList>
              {toolkitErrorCodeDescriptions.map((param) => (
                <li key={param.key}>
                  <strong>{param.key}</strong>: {param.description}
                </li>
              ))}
            </ParameterList>
          </InfoContainer>
        </Grid>
      </Grid>

      <OvCompactButton
        onClick={handleUpdateConfiguration}
        icon={<UpdateIcon />}
      >
        {t('configurationSettings.update')}
      </OvCompactButton>

      {isLoading && <OvLoadingIndicator position="fixed" />}

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={openErrorSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <ErrorMessageContainer>
          <OvErrorMessage message={errorMessage} />
        </ErrorMessageContainer>
      </Snackbar>
    </Container>
  );
};

export default ConfigurationErrorMessages;

const Container = styled.div`
  margin-left: 1rem;
  box-shadow: ${Variables.boxShadow.defaultBox};
  background-color: ${Colours.WHITE};
  padding: 1.5rem;
  border-radius: ${Variables.borderRadius.LARGE};
`;

const JsonContainer = styled.div`
  padding-bottom: 1.5rem;
`;

const ErrorMessageContainer = styled.div`
  padding: 1rem;
  border-radius: ${Variables.borderRadius.LARGE};
  background-color: ${Colours.STRAWBERRY['50']};
`;

const InfoContainer = styled.div`
  padding: 1rem;
  background-color: ${Colours.GRAY['100']};
  border-radius: ${Variables.borderRadius.SMALL};
`;

const ParameterList = styled.ul`
  list-style-type: none;
  padding-left: 0;

  li {
    margin-bottom: 0.5rem;
  }
`;
