import React, { FC } from 'react';
import { Switch } from '@mui/material';
import styled from 'styled-components';
import Colours from '../../../design-system/colours';
import Variables from '../../../design-system/variables';

export interface OvToggleProps {
  label?: string;
  toggleOnLabel?: string;
  toggleOffLabel?: string;
  value: boolean;
  disabled?: boolean;
  onChange: (value: boolean) => void;
}

const OvToggle: FC<OvToggleProps> = ({
  label,
  toggleOnLabel,
  toggleOffLabel,
  value,
  disabled,
  onChange,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked);
  };

  return (
    <Container>
      <WidgetLabel>{label}</WidgetLabel>
      <StyledLabel>
        {value ? toggleOnLabel : toggleOffLabel}
        <Switch disabled={disabled} checked={value} onChange={handleChange} />
      </StyledLabel>
    </Container>
  );
};

export default OvToggle;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
`;

const WidgetLabel = styled.span`
  color: ${Colours.OV_BASE_LIGHT};
  font-size: ${Variables.fontSizes.LARGE};
`;

const StyledLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem 0 0.25rem 0.75rem;
  min-width: 8.5rem;
  background-color: ${Colours.OV_YELLOW};
  border-radius: ${Variables.borderRadius.XLARGE};
  cursor: pointer;
`;
