import React, { FC, MouseEvent } from 'react';
import styled from 'styled-components';
import { Question } from '../../../common/model/dto/survey/question';
import { QuestionTypeEnum } from '../../../common/model/dto/survey/question-type.enum';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import PollIcon from '@mui/icons-material/Poll';
import Colours from '../../../design-system/colours';
import DeleteIcon from '@mui/icons-material/Delete';
import ErrorIcon from '@mui/icons-material/Error';
import SportsScoreIcon from '@mui/icons-material/SportsScore';
import { IconButton } from '@mui/material';

interface QuestionListProps {
  questions?: Question[];
  activeQuestion?: Question;
  isCurrentQuestionValid?: boolean;
  onSelectQuestion: (question: Question) => void;
  onRemoveQuestion: (question: Question) => void;
}

const OvSurveyBuilderQuestionList: FC<QuestionListProps> = ({
  questions,
  onSelectQuestion,
  onRemoveQuestion,
  activeQuestion,
  isCurrentQuestionValid,
}) => {
  const getIcon = (type: QuestionTypeEnum) => {
    switch (type) {
      case QuestionTypeEnum.TEXT:
        return <TextFieldsIcon />;
      case QuestionTypeEnum.NPS:
        return <PollIcon />;
      default:
        return null;
    }
  };

  const handleQuestionDelete = (event: MouseEvent, question: Question) => {
    event.stopPropagation();
    onRemoveQuestion(question);
  };

  return (
    <List>
      {questions?.map((question) => (
        <ListItem
          key={question.id}
          onClick={() => onSelectQuestion(question)}
          isActive={question.id === activeQuestion?.id}
        >
          {question.id === activeQuestion?.id && !isCurrentQuestionValid && (
            <StyledErrorIcon />
          )}
          {question.represent_nps_score && <SportsScoreIcon />}
          {getIcon(question.type)}

          <QuestionName>{question.label}</QuestionName>

          {questions?.length > 1 && (
            <StyledIconButton style={{ marginLeft: 'auto' }}>
              <DeleteIcon
                onClick={(event) => handleQuestionDelete(event, question)}
              />
            </StyledIconButton>
          )}
        </ListItem>
      ))}
    </List>
  );
};

export default OvSurveyBuilderQuestionList;

const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const ListItem = styled.li<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  padding: 1rem 0.5rem;
  gap: 0.5rem;
  cursor: pointer;
  border-bottom: 1px solid ${Colours.OV_LIGHT_GRAY};
  background-color: ${({ isActive }) =>
    isActive ? Colours.LIGHT_LEMON : 'transparent'};

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background-color: ${Colours.LIGHT_LEMON};
  }
`;

const QuestionName = styled.span`
  font-weight: bold;
`;

const StyledIconButton = styled(IconButton)`
  && {
    color: ${Colours.OV_BASE};
    height: 2rem;
    width: 2rem;

    .MuiSvgIcon-root {
      width: 1.5rem;
      height: 1.5rem;
    }

    &.MuiButtonBase-root {
      padding: 0.375rem;
      border-radius: 50%;
    }
  }
`;

const StyledErrorIcon = styled(ErrorIcon)`
  && {
    color: ${Colours.OV_RED};
  }
`;
