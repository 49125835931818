import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from 'styled-components';
import Variables from '../../design-system/variables';
import Colours from '../../design-system/colours';
import OvPageTitle from '../UI/molecules/OvPageTitle';
import PollIcon from '@mui/icons-material/Poll';
import OvToggle from '../UI/atoms/OvToggle';
import { NpsSurveyTabType } from '../../common/model/type/nps-survey-tab.type';
import { Tab, Tabs } from '@mui/material';
import {
  Link,
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import { last } from 'lodash';
import OvNpsSurveySetupTab from '../UI/organisms/OvNpsSurveySetupTab';
import OvNpsSurveyDashboardTab from '../UI/organisms/OvNpsSurveyDashboardTab';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { NpsSurveySettings } from '../../common/model/dto/survey/nps-survey-settings';
import {
  getCurrentNpsSurvey,
  getNpsSurveySettings,
  updateNpsSurveySettings,
} from '../../redux/thunks/admin/admin-nps-survey.thunk';
import OvErrorMessage from '../UI/atoms/OvErrorMessage';

const NpsSurvey: FC = () => {
  const { t } = useTranslation();
  const { url, path } = useRouteMatch();
  let location = useLocation();
  const dispatch = useAppDispatch();
  const surveySettings: NpsSurveySettings = useAppSelector(
    (state) => state.adminNpsSurvey.npsSurveySettings
  );
  const isLoading = useAppSelector((state) => state.adminNpsSurvey.loading);
  const errorMessage = useAppSelector((state) => state.adminNpsSurvey.error);
  const [activeTab, setActiveTab] = useState<NpsSurveyTabType>(
    NpsSurveyTabType.SETUP
  );

  const handleNpsSurveyEnabledChange = (enabled: boolean) => {
    dispatch(updateNpsSurveySettings({ ...surveySettings, enabled }));
  };

  useEffect(() => {
    const pathName = last(location.pathname.split('/')) as NpsSurveyTabType;

    if (Object.values(NpsSurveyTabType).includes(pathName)) {
      setActiveTab(pathName);
    }

    dispatch(getNpsSurveySettings());
    dispatch(getCurrentNpsSurvey());
    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      <OvPageTitle
        title={t('survey.npsSurvey')}
        icon={<StyledPollIcon />}
        infoTooltip={t('survey.describeNps')}
      >
        <OvToggle
          label={t('common.status')}
          toggleOnLabel={t('common.enabled')}
          toggleOffLabel={t('common.disabled')}
          value={!!surveySettings?.enabled}
          disabled={isLoading}
          onChange={handleNpsSurveyEnabledChange}
        />
      </OvPageTitle>

      <TabContainer>
        <Tabs
          onChange={(event: any, newValue: NpsSurveyTabType) => {
            setActiveTab(newValue);
          }}
          value={activeTab}
        >
          <Tab
            style={{
              borderBottom: '2px solid rgba(0, 0, 0, 0.38)',
              textTransform: 'none',
            }}
            value={NpsSurveyTabType.SETUP}
            label={t('survey.setup')}
            component={Link}
            to={`${url}/${NpsSurveyTabType.SETUP}`}
          />
          <Tab
            style={{
              borderBottom: '2px solid rgba(0, 0, 0, 0.38)',
              textTransform: 'none',
            }}
            value={NpsSurveyTabType.DASHBOARD}
            label={t('survey.dashboard')}
            component={Link}
            to={`${url}/${NpsSurveyTabType.DASHBOARD}`}
          />
        </Tabs>
      </TabContainer>

      <Switch>
        <Route exact path={path}>
          <Redirect to={`${url}/${NpsSurveyTabType.SETUP}`} />
        </Route>
        <Route path={`${path}/${NpsSurveyTabType.SETUP}`}>
          <OvNpsSurveySetupTab />
        </Route>
        <Route path={`${path}/${NpsSurveyTabType.DASHBOARD}`}>
          <OvNpsSurveyDashboardTab />
        </Route>
      </Switch>

      {errorMessage && (
        <ErrorMessageContainer>
          <OvErrorMessage message={errorMessage} />
        </ErrorMessageContainer>
      )}
    </Container>
  );
};

export default NpsSurvey;

const Container = styled.div`
  margin-left: 1rem;
  box-shadow: ${Variables.boxShadow.defaultBox};
  background-color: ${Colours.WHITE};
  padding: 1.5rem;
  border-radius: ${Variables.borderRadius.LARGE};
`;

const StyledPollIcon = styled(PollIcon)`
  && {
    margin-right: 0.5rem;
  }
`;

const TabContainer = styled.div`
  margin-bottom: 1.5rem;

  && {
    .MuiTabs-indicator {
      background-color: ${Colours.OV_BASE};
    }
  }
`;

const ErrorMessageContainer = styled.div`
  margin-top: 1rem;
`;
