import styled from 'styled-components';
import Colours from '../../../design-system/colours';
import { FC, useEffect, useState } from 'react';
import Variables from '../../../design-system/variables';

export interface OvNpsSurveyQuestionProps {
  leftLabel?: string | null;
  rightLabel?: string | null;
  onChange?: (value: number) => void;
  value?: number;
}

const OvNpsSurveyQuestion: FC<OvNpsSurveyQuestionProps> = ({
  leftLabel,
  rightLabel,
  onChange,
  value,
}) => {
  const [selectedValue, setSelectedValue] = useState<number | null>(null);

  useEffect(() => {
    if (value !== selectedValue && value) {
      setSelectedValue(value);
    }
  }, [value, selectedValue]);

  const handleValueChange = (value: number) => {
    setSelectedValue(value);
    onChange && onChange(value);
  };

  return (
    <Container>
      <QuestionLabel>{leftLabel}</QuestionLabel>
      <OptionsContainer>
        {Array.from({ length: 10 }, (_, i) => i + 1).map((value) => (
          <QuestionOption
            className={selectedValue === value ? 'selected' : ''}
            key={value}
            onClick={() => handleValueChange(value)}
          >
            {value}
          </QuestionOption>
        ))}
      </OptionsContainer>
      <QuestionLabel>{rightLabel}</QuestionLabel>
    </Container>
  );
};

export default OvNpsSurveyQuestion;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
`;

const QuestionLabel = styled.span`
  color: ${Colours.OV_BASE_LIGHT};
  font-size: ${Variables.fontSizes.SMALL};
`;

const OptionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
`;

const QuestionOption = styled.button`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid ${Colours.OV_BASE_LIGHT};
  border-radius: 50%;
  padding: 0.625rem;
  width: 1.875rem;
  height: 1.875rem;

  &:hover,
  &.selected {
    background-color: ${Colours.LIGHT_LEMON};
    border-color: ${Colours.OV_YELLOW};
  }
`;
