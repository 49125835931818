export const API_PATH_PREFIX = '/api/2023-03';
export const ADMIN_DAILY_DATA_ROUTE = '/admin/daily-data';
export const ADMIN_SCANS_ROUTE = 'admin/scans';
export const ADMIN_JOURNAL_ENTRIES_ROUTE = '/admin/journal-entries';
export const ADMIN_USERS_ROUTE = '/admin/users';
export const ADMIN_LOTS = '/admin/lots';
export const ADMIN_CLINIC_LOCATION_ROUTE = '/admin/clinic-locations';
export const ADMIN_CLINIC_LOCATION_PROVIDER_ROUTE =
  '/admin/clinic-location-providers';
export const ADMIN_CLINIC_LOCATION_PATIENT_ROUTE =
  '/admin/clinic-location-patients';
export const ADMIN_ORDERS_ROUTE = 'admin/orders';
export const ADMIN_ORDER_ACTIVITIES_ROUTE = 'admin/order-activities';
export const CLINIC_LOCATION_ROUTE = '/clinic-locations';
export const CLINIC_LOCATION_PROVIDERS_ROUTE = '/clinic-location-providers';
export const CLINIC_LOCATION_PATIENTS_ROUTE = '/clinic-location-patients';
export const PROVIDER_PATIENTS_ROUTE = '/provider-patients';
export const CLINICS_ROUTE = '/clinics';
export const USERS_ROUTE = '/users';
export const REPORTS_ROUTE = '/reports';
export const DAILY_DATA_ROUTE = '/daily-data';
export const DEMO_ACCOUNT_DATA_ROUTE = '/demo-accounts';
export const ADMIN_SENDGRID_ROUTE = '/admin/sendgrid';
export const ADMIN_SCAN_SEQUENCES_ROUTE = '/admin/scan-sequences';
export const ADMIN_PRODUCTS_ROUTE = '/admin/products';
export const ADMIN_ARTICLES_ROUTE = '/admin/articles';
export const SENDGRID_ROUTE = '/sendgrid';
export const CONFIGURATION_ROUTE = '/configuration';
export const ADMIN_CONFIGURATION_ROUTE = '/admin/configuration';
export const ADMIN_PRODUCT_PRICE_ROUTE = 'admin/product-prices';
export const ADMIN_ENTITLEMENT_ROUTE = 'admin/entitlements';
export const ADMIN_NOTIFICATION_CONTENTS_ROUTE = 'admin/notification-contents';
export const ADMIN_NOTIFICATION_ROUTE = 'admin/notifications';
export const ADMIN_ANALYTICS_ROUTE = 'admin/analytics';
export const ADMIN_CLINIC_ROUTE = 'admin/clinics';
export const ADMIN_USER_SCANNING_PLANS = 'admin/user-scanning-plans';
export const ADMIN_NPS_SURVEY_ROUTE = 'admin/nps-survey';
