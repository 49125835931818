import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { NpsSurveyService } from '../../../services/nps-survey.service';
import { NpsSurveySettings } from '../../../common/model/dto/survey/nps-survey-settings';
import { Survey } from '../../../common/model/dto/survey/survey';
import { History } from 'history';
import { QuestionTypeEnum } from '../../../common/model/dto/survey/question-type.enum';

export const getCurrentNpsSurvey = createAsyncThunk(
  'adminNpsSurvey/getCurrentNpsSurvey',
  async (_, { rejectWithValue }) => {
    try {
      return await NpsSurveyService.getCurrentNpsSurvey();
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        return rejectWithValue(error.response.data);
      }

      return rejectWithValue(error);
    }
  }
);

export const getNpsSurveyById = createAsyncThunk(
  'adminNpsSurvey/getNpsSurveyById',
  async (id: string, { rejectWithValue }) => {
    try {
      return await NpsSurveyService.getNpsSurveyById(id);
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        return rejectWithValue(error.response.data);
      }

      return rejectWithValue(error);
    }
  }
);

export const createNpsSurvey = createAsyncThunk(
  'adminNpsSurvey/createNpsSurvey',
  async (
    { npsSurvey, history }: { npsSurvey: Survey; history: History },
    { rejectWithValue }
  ) => {
    try {
      const survey = await NpsSurveyService.createNpsSurvey(npsSurvey);
      history.push(`/surveys/nps/setup`);

      return survey;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        return rejectWithValue(error.response.data);
      }

      return rejectWithValue(error);
    }
  }
);

export const updateNpsSurvey = createAsyncThunk(
  'adminNpsSurvey/updateNpsSurvey',
  async (
    { npsSurvey, history }: { npsSurvey: Survey; history: History },
    { rejectWithValue }
  ) => {
    try {
      if (
        npsSurvey?.questions?.filter(
          ({ represent_nps_score }) => !!represent_nps_score
        ).length !== 1 ||
        !npsSurvey?.questions?.find(
          ({ represent_nps_score, type }) =>
            !!represent_nps_score && type === QuestionTypeEnum.NPS
        )
      ) {
        throw new Error(
          'Invalid survey configuration: One NPS question must represent the score.'
        );
      }

      const survey = await NpsSurveyService.updateNpsSurvey(npsSurvey);
      history.push(`/surveys/nps/setup`);

      return survey;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        return rejectWithValue(error.response.data);
      }

      return rejectWithValue(error);
    }
  }
);

export const getNpsSurveySettings = createAsyncThunk(
  'adminNpsSurvey/getNpsSurveySettings',
  async (_, { rejectWithValue }) => {
    try {
      return await NpsSurveyService.getNpsSurveySettings();
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        return rejectWithValue(error.response.data);
      }

      return rejectWithValue(error);
    }
  }
);

export const updateNpsSurveySettings = createAsyncThunk(
  'adminNpsSurvey/updateNpsSurveySettings',
  async (surveySettings: NpsSurveySettings, { rejectWithValue }) => {
    try {
      return await NpsSurveyService.updateNpsSurveySettings(surveySettings);
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        return rejectWithValue(error.response.data);
      }

      return rejectWithValue(error);
    }
  }
);
