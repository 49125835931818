import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Survey } from '../../../common/model/dto/survey/survey';
import OvSurveyQuestion from '../molecules/OvSurveyQuestion';
import OvCompactButton from '../atoms/OvCompactButton';
import { useTranslation } from 'react-i18next';
import { SurveyResponse } from '../../../common/model/dto/survey/survey-response';
import { SurveyQuestionResponse } from '../../../common/model/dto/survey/survey-question-response';
import OvSurveySubmitScreen from '../atoms/OvSurveySubmitScreen';

export interface OvSurveyProps {
  survey: Survey;
  isPreviewMode?: boolean;
  onSubmitSuccess?: () => void;
}

const OvSurvey: FC<OvSurveyProps> = ({
  survey,
  isPreviewMode,
  onSubmitSuccess,
}) => {
  const { t } = useTranslation();
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [surveyResponse, setSurveyResponse] = useState<SurveyResponse>();
  let surveyElement;

  useEffect(() => {
    setSurveyResponse({
      survey_id: survey.id,
      question_responses: survey.questions?.map(({ id, value }) => ({
        question_id: id,
        value,
        option_id: '',
      })),
    });
  }, [survey]);

  const handleResponseChange = (questionResponse: SurveyQuestionResponse) => {
    setSurveyResponse((prevSurveyResponse) => ({
      ...prevSurveyResponse,
      survey_id: prevSurveyResponse!.survey_id,
      question_responses:
        prevSurveyResponse?.question_responses?.map((response) =>
          response.question_id === questionResponse.question_id
            ? questionResponse
            : response
        ) || [],
    }));
  };

  const handleSubmit = () => {
    if (isPreviewMode) {
      submitSuccess();
    } else {
      // TODO: Submit survey response to backend and call submitSuccess on success
      console.log(surveyResponse);
      submitSuccess();
    }
  };

  const submitSuccess = () => {
    setIsSubmitted(true);

    if (onSubmitSuccess) {
      onSubmitSuccess();
    }
  };

  const isCurrentStepValid = (): boolean => {
    const currentQuestionResponse = surveyResponse?.question_responses?.find(
      ({ question_id }) =>
        survey.questions && question_id === survey.questions[currentIndex].id
    );

    return (
      (survey.questions && !survey.questions[currentIndex].required) ||
      !!currentQuestionResponse?.value
    );
  };

  if (survey.questions?.length) {
    surveyElement = (
      <>
        {!isSubmitted && (
          <>
            <OvSurveyQuestion
              value={
                surveyResponse?.question_responses &&
                surveyResponse?.question_responses[currentIndex].value
              }
              onResponseChange={handleResponseChange}
              question={survey.questions[currentIndex]}
              key={survey.questions[currentIndex].id}
            />

            <ActionContainer>
              {currentIndex !== 0 && (
                <StyledOvCompactButton
                  onClick={() => setCurrentIndex(currentIndex - 1)}
                >
                  {t('common.actions.prev')}
                </StyledOvCompactButton>
              )}
              {currentIndex !== survey.questions?.length - 1 ? (
                <StyledOvCompactButton
                  disabled={!isCurrentStepValid()}
                  onClick={() => setCurrentIndex(currentIndex + 1)}
                >
                  {t('common.actions.next')}
                </StyledOvCompactButton>
              ) : (
                <StyledOvCompactButton
                  disabled={!isCurrentStepValid()}
                  onClick={handleSubmit}
                >
                  {t('common.submit')}
                </StyledOvCompactButton>
              )}
            </ActionContainer>
          </>
        )}

        {isSubmitted && survey.submit_screen && (
          <OvSurveySubmitScreen
            title={survey.submit_screen.title}
            description={survey.submit_screen.description || ''}
          />
        )}
      </>
    );
  }

  return <>{surveyElement}</>;
};

export default OvSurvey;

const ActionContainer = styled.div`
  margin-top: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`;

const StyledOvCompactButton = styled(OvCompactButton)`
  && {
    padding: 0.25rem 2rem;
    width: 7rem;
  }
`;
