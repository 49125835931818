import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import { Order } from '../../../common/model/dto/order/order';
import DateUtils from '../../../common/utils/services/date-utils';
import { OrderDataUtils } from '../../../common/utils/services/order-data-utils';
import StringUtils from '../../../common/utils/services/string-utils';
import Colours from '../../../design-system/colours';
import Variables from '../../../design-system/variables';
import OvButton from '../atoms/OvButton';
import OvListTableButtonField from '../atoms/OvListTableButtonField';
import OvListTableField from '../atoms/OvListTableField';
import OvListTableLabelField from '../atoms/OvListTableLabelField';
import { isEmpty } from 'lodash';

const OvOrderListRow: FC<{ order: Order }> = ({ order }) => {
  const { t } = useTranslation();
  let { url } = useRouteMatch();

  const totalPrice = order?.items.reduce(
    (partialSum, item) =>
      partialSum +
      ((item.price ?? 0) - (item.discount ?? 0)) * (item.quantity ?? 1),
    0
  );

  const totalItems = order?.items.reduce(
    (partialSum, item) => partialSum + (item.quantity ?? 1),
    0
  );

  const totalDiscountAmount = order?.items.reduce(
    (partialSum, item) =>
      partialSum + (item.discount ?? 0) * (item.quantity ?? 1),
    0
  );

  const orderedAt = order.ordered_at
    ? DateUtils.formatDate(new Date(order.ordered_at), 'DD MMM, H:mm')
    : '';

  return (
    <>
      <OvListTableField>
        {StringUtils.displayValue(`${order?.internal_id || ''}`)}
        {order.stripe_payment_intent_id && (
          <>
            <br />
            <a
              href={`https://dashboard.stripe.com/payments/${order.stripe_payment_intent_id}`}
              target="blank"
            >
              {t('orders.stripePaymentLink')}
            </a>
          </>
        )}
        {order.impact_click_id && (
          <AffiliateStatus>{t('orders.affiliate')}</AffiliateStatus>
        )}
      </OvListTableField>
      <OvListTableField>{orderedAt}</OvListTableField>
      <OvListTableField>
        {StringUtils.displayValue(
          `${
            order.billing_address?.first_name
              ? order.billing_address.first_name?.substring(0, 1)
              : '-'
          }. ${
            order.billing_address?.last_name
              ? order.billing_address.last_name
              : '-'
          }`
        )}
      </OvListTableField>
      <OvListTableField>
        {totalItems} item{totalItems > 1 ? 's' : ''} <br />$
        {totalPrice.toFixed(2)}
      </OvListTableField>
      <OvListTableField>
        {!isEmpty(order.discount_code) ? (
          <>
            {order.discount_code}
            <br />
          </>
        ) : null}
        {isEmpty(order.discount_code) && totalDiscountAmount > 0 ? (
          <>
            <span style={{ color: Colours.OV_RED }}>
              {t('orders.missingCode')}
            </span>
            <br />
          </>
        ) : null}
        {totalDiscountAmount ? `-$${totalDiscountAmount.toFixed(2)}` : '-'}
      </OvListTableField>
      <OvListTableLabelField>
        <StyledLabel
          style={{
            backgroundColor: OrderDataUtils.getStatusBackgroundColor(order),
            color: OrderDataUtils.getStatusFontColor(order),
          }}
        >
          {order.status}
        </StyledLabel>
      </OvListTableLabelField>
      <OvListTableLabelField>
        <StyledLabel
          style={{
            backgroundColor: OrderDataUtils.getPaymentStatusColor(order),
          }}
        >
          {order.payment_status}
        </StyledLabel>
      </OvListTableLabelField>
      <OvListTableLabelField>
        <StyledLabel
          style={{
            backgroundColor: OrderDataUtils.getFulFillmentColor(order),
          }}
        >
          {order.fulfillment_status}
        </StyledLabel>
      </OvListTableLabelField>
      <OvListTableButtonField>
        <StyledOvButton>
          <NavLink to={`${url}/${order.id}`}>
            {t('orders.orderDetailsCta')}
          </NavLink>
        </StyledOvButton>
      </OvListTableButtonField>
    </>
  );
};

export default OvOrderListRow;

const StyledOvButton = styled(OvButton)`
  && {
    border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
    padding: 0;
    margin: 0;
    background-color: ${Colours.OV_BASE};

    &:hover {
      a {
        color: ${Colours.OV_BASE};
      }
    }

    a {
      border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
      padding: 0.25rem 1rem;
      text-decoration: none;
      text-transform: none;
      font-weight: bold;
      font-size: 0.75rem;
      color: ${Colours.OV_WHITE};
    }
  }
`;

const StyledLabel = styled.p`
  text-align: center;
  border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
  padding: 0.25rem 0.3rem;
`;

const AffiliateStatus = styled.p`
  padding: 0;
  margin: 0.1rem 0 0 0;
  color: ${Colours.OV_ORDER_CREATED};
`;
