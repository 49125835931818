import React from 'react';
import styled from 'styled-components';
import { QuestionTypeEnum } from '../../../common/model/dto/survey/question-type.enum';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import PollIcon from '@mui/icons-material/Poll';
import { useTranslation } from 'react-i18next';
import Colours from '../../../design-system/colours';

interface OvQuestionTypeSelectorProps {
  selectedType?: QuestionTypeEnum;
  onSelect: (type: QuestionTypeEnum) => void;
}

const OvQuestionTypeSelector: React.FC<OvQuestionTypeSelectorProps> = ({
  selectedType,
  onSelect,
}) => {
  const { t } = useTranslation();
  const iconStyle = { width: '2rem', height: '2rem' };

  return (
    <Container>
      <Option
        selected={selectedType === QuestionTypeEnum.TEXT}
        onClick={() => onSelect(QuestionTypeEnum.TEXT)}
      >
        <TextFieldsIcon style={iconStyle} />
        <OptionLabel>{t('survey.question.types.text')}</OptionLabel>
      </Option>
      <Option
        selected={selectedType === QuestionTypeEnum.NPS}
        onClick={() => onSelect(QuestionTypeEnum.NPS)}
      >
        <PollIcon style={iconStyle} />
        <OptionLabel>{t('survey.question.types.nps')}</OptionLabel>
      </Option>
    </Container>
  );
};

export default OvQuestionTypeSelector;

const Container = styled.div`
  display: flex;
  gap: 1rem;
`;

const Option = styled.button<{ selected: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  padding: 1rem;
  border: 2px solid transparent;
  background-color: ${({ selected }) =>
    selected ? Colours.OV_YELLOW : 'transparent'};
  border-radius: 1rem;
  transition: border-color 0.3s;
  flex-basis: 7rem;

  &:hover {
    border-color: ${Colours.OV_YELLOW};
  }
`;

const OptionLabel = styled.span`
  margin-top: 0.5rem;
  font-size: 0.875rem;
  text-align: center;
`;
