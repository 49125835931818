import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import { addDays, isSameDay, subDays } from 'date-fns';
import { UserInfo } from '../../../common/model/dto/user-info';
import Colours from '../../../design-system/colours';
import DateUtils from '../../../common/utils/services/date-utils';
import { ScanType } from '../../../common/model/dto/scan-sequences/scan-type.enum';
import Variables from '../../../design-system/variables';
import { DailyData } from '../../../common/model/dto/daily-data';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { clearDailyDataList } from '../../../redux/reducers/daily-data.slice';
import { loadUserDailyData } from '../../../redux/thunks/daily-data.thunk';
import { TryingToConceiveStatus } from '../../../common/model/dto/trying-to-conceive-status.enum';
import { Tooltip } from '@mui/material';
import StringUtils from '../../../common/utils/services/string-utils';
import OvLoadingIndicator from '../atoms/OvLoadingIndicator';
import { useTranslation } from 'react-i18next';

const PERIOD_FLOW_VALUES = ['Light', 'Medium', 'Heavy'];
const DAYS_BEFORE_AND_AFTER_TODAY = 35;

const OvScanningPlanTable: FC<{ user: UserInfo }> = ({ user }) => {
  const { t } = useTranslation();
  const today = new Date();
  const startDate = subDays(today, DAYS_BEFORE_AND_AFTER_TODAY);
  const endDate = addDays(today, DAYS_BEFORE_AND_AFTER_TODAY);
  const datesArray = DateUtils.getDatesBeforeAndAfterStartDate(
    today,
    DAYS_BEFORE_AND_AFTER_TODAY
  );
  const dispatch = useAppDispatch();
  const userDailyDataArray: DailyData[] = useAppSelector(
    (state) => state.dailyData.dailyDataList
  );
  const isLoading: boolean = useAppSelector(
    (state) => state.dailyData.isUserDailyDataLoading
  );

  useEffect(() => {
    dispatch(clearDailyDataList());
    dispatch(
      loadUserDailyData({
        startDate: startDate,
        endDate: endDate,
        userDocumentId: user.document_id!,
      })
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const getDailyDataForDate = (date: Date) => {
    return userDailyDataArray.find(
      (dailyData) => dailyData.day === DateUtils.getDbDateTag(date)
    );
  };

  const shouldScanHormone = (
    date: Date,
    scanType: ScanType,
    scanningPlanType: 'recommended' | 'active' | 'next-recommended'
  ) => {
    let scanningPlan;

    switch (scanningPlanType) {
      case 'recommended':
        scanningPlan = user.recommended_scanning_plan;
        break;
      case 'active':
        scanningPlan = user.active_scanning_plan_snapshot;
        break;
      case 'next-recommended':
        scanningPlan = user.next_recommended_scanning_plan;
    }

    const dayString = DateUtils.getDbDateTag(date);
    const scanningDay = scanningPlan?.days.find((day) => day.day === dayString);

    return scanningDay?.scan_types?.includes(scanType);
  };

  const isPeriodDay = (dailyData?: DailyData) => {
    if (
      !dailyData ||
      !dailyData.journal_entry ||
      !dailyData.journal_entry?.period_flow
    ) {
      return false;
    }

    return PERIOD_FLOW_VALUES.includes(dailyData.journal_entry.period_flow);
  };

  const getDayStatusColor = (dailyData?: DailyData) => {
    if (!dailyData || !dailyData.trying_to_conceive_status) {
      return 'none';
    }

    const tryingToConceiveStatus = dailyData.trying_to_conceive_status;

    switch (tryingToConceiveStatus) {
      case TryingToConceiveStatus.LOW:
        return Colours.PALE_LIME;
      case TryingToConceiveStatus.FERTILE:
        return Colours.OV_FERTILE;
      case TryingToConceiveStatus.HIGH:
        return Colours.OV_HIGH_TRYING_TO_CONCEIVE;
      case TryingToConceiveStatus.PEAK:
        return Colours.OV_HIGH_TRYING_TO_CONCEIVE;
      case TryingToConceiveStatus.POST_OVULATION:
        return Colours.POST_OVULATION;
      default:
        return 'transparent';
    }
  };

  const getScanTooltipText = (dailyData?: DailyData): string => {
    return `
        lh: ${StringUtils.displayValue(dailyData?.lh?.toString())}, 
        pg: ${StringUtils.displayValue(dailyData?.pg?.toString())}, 
        lr_e3g: ${StringUtils.displayValue(dailyData?.lr_e3g?.toString())}
    `;
  };

  const isValidScanStatusForTable = (date: Date) => {
    const dailyData = userDailyDataArray.find(
      (dailyData) => dailyData.day === DateUtils.getDbDateTag(date)
    );

    return (
      dailyData?.trying_to_conceive_status &&
      dailyData?.trying_to_conceive_status !== 'period'
    );
  };

  return (
    <TableContainer>
      <StyledTable>
        {isLoading ? (
          <OvLoadingIndicator />
        ) : (
          <>
            <thead>
              <tr>
                <StyledLabelCellHeader />
                {datesArray.map((date, index) => (
                  <StyledDayHeader key={index} isToday={isSameDay(date, today)}>
                    <StyledMonthName>
                      {date.toLocaleString('default', { month: 'short' })}
                    </StyledMonthName>
                    {date.getDate()}
                  </StyledDayHeader>
                ))}
              </tr>
            </thead>
            <tbody>
              {user.recommended_scanning_plan && (
                <>
                  <tr key={'recommended-lh-pg'} style={{ opacity: '0.6' }}>
                    <StyledLabelCell>
                      {t('userDetails.scanningPlans.recommendedLhPg')}
                    </StyledLabelCell>
                    {datesArray.map((date, dayIndex) => (
                      <StyledDayCell
                        key={dayIndex}
                        isToday={isSameDay(date, today)}
                      >
                        {shouldScanHormone(
                          date,
                          ScanType.LH_PG,
                          'recommended'
                        ) && <StyledLhPgCellContent />}
                      </StyledDayCell>
                    ))}
                  </tr>
                  <tr key={'recommended-lr-e3g'} style={{ opacity: '0.6' }}>
                    <StyledLabelCell>
                      {t('userDetails.scanningPlans.recommendedLrE3g')}
                    </StyledLabelCell>
                    {datesArray.map((date, dayIndex) => (
                      <StyledDayCell
                        key={dayIndex}
                        isToday={isSameDay(date, today)}
                      >
                        {shouldScanHormone(
                          date,
                          ScanType.LR_E3G,
                          'recommended'
                        ) && <StyledE3gCellContent />}
                      </StyledDayCell>
                    ))}
                  </tr>
                  {user.next_recommended_scanning_plan && (
                    <>
                      <tr key={'next-recommended-lh-pg'}>
                        <StyledLabelCell
                          style={{
                            fontWeight: 'bold',
                          }}
                        >
                          {t('userDetails.scanningPlans.nextRecommendedLhPg')}
                        </StyledLabelCell>
                        {datesArray.map((date, dayIndex) => (
                          <StyledDayCell
                            key={dayIndex}
                            isToday={isSameDay(date, today)}
                          >
                            {shouldScanHormone(
                              date,
                              ScanType.LH_PG,
                              'next-recommended'
                            ) && <StyledLhPgCellContent />}
                          </StyledDayCell>
                        ))}
                      </tr>
                      <tr key={'next-recommended-lr-e3g'}>
                        <StyledLabelCell
                          style={{
                            fontWeight: 'bold',
                          }}
                        >
                          {t('userDetails.scanningPlans.nextRecommendedLrE3g')}
                        </StyledLabelCell>
                        {datesArray.map((date, dayIndex) => (
                          <StyledDayCell
                            key={dayIndex}
                            isToday={isSameDay(date, today)}
                          >
                            {shouldScanHormone(
                              date,
                              ScanType.LR_E3G,
                              'next-recommended'
                            ) && <StyledE3gCellContent />}
                          </StyledDayCell>
                        ))}
                      </tr>
                    </>
                  )}
                  {user.active_scanning_plan_snapshot && (
                    <>
                      <tr
                        key={'active-lh-pg'}
                        style={{ backgroundColor: Colours.OV_WHITE_HOVER }}
                      >
                        <StyledLabelCell
                          style={{
                            fontWeight: 'bold',
                            backgroundColor: Colours.OV_WHITE_HOVER,
                          }}
                        >
                          {t('userDetails.scanningPlans.activeLgPg')}
                        </StyledLabelCell>
                        {datesArray.map((date, dayIndex) => (
                          <StyledDayCell
                            key={dayIndex}
                            isToday={isSameDay(date, today)}
                          >
                            {shouldScanHormone(
                              date,
                              ScanType.LH_PG,
                              'active'
                            ) && <StyledLhPgCellContent />}
                          </StyledDayCell>
                        ))}
                      </tr>
                      <tr
                        key={'active-lr-e3g'}
                        style={{ backgroundColor: Colours.OV_WHITE_HOVER }}
                      >
                        <StyledLabelCell
                          style={{
                            fontWeight: 'bold',
                            backgroundColor: Colours.OV_WHITE_HOVER,
                          }}
                        >
                          {t('userDetails.scanningPlans.activeLrE3g')}
                        </StyledLabelCell>
                        {datesArray.map((date, dayIndex) => (
                          <StyledDayCell
                            key={dayIndex}
                            isToday={isSameDay(date, today)}
                          >
                            {shouldScanHormone(
                              date,
                              ScanType.LR_E3G,
                              'active'
                            ) && <StyledE3gCellContent />}
                          </StyledDayCell>
                        ))}
                      </tr>
                    </>
                  )}
                  <tr key={'period'}>
                    <StyledLabelCell>
                      {t('userDetails.scanningPlans.periodDays')}
                    </StyledLabelCell>
                    {datesArray.map((date, dayIndex) => (
                      <StyledDayCell
                        key={dayIndex}
                        isToday={isSameDay(date, today)}
                      >
                        {isPeriodDay(
                          userDailyDataArray.find(
                            (dailyData) =>
                              dailyData.day === DateUtils.getDbDateTag(date)
                          )
                        ) && <StyledPeriodDayContent />}
                      </StyledDayCell>
                    ))}
                  </tr>
                  <tr key={'scans'}>
                    <StyledLabelCell>
                      {t('userDetails.scanningPlans.scans')}
                    </StyledLabelCell>
                    {datesArray.map((date, dayIndex) => (
                      <StyledDayCell
                        key={dayIndex}
                        isToday={isSameDay(date, today)}
                      >
                        {getDailyDataForDate(date) && (
                          <Tooltip
                            title={getScanTooltipText(
                              getDailyDataForDate(date)
                            )}
                          >
                            <StyledDayCellContent
                              style={{
                                backgroundColor: getDayStatusColor(
                                  userDailyDataArray.find(
                                    (dailyData) =>
                                      dailyData.day ===
                                      DateUtils.getDbDateTag(date)
                                  )
                                ),
                                border: isValidScanStatusForTable(date)
                                  ? `3px solid ${Colours.OV_BASE}`
                                  : 'none',
                              }}
                            />
                          </Tooltip>
                        )}
                      </StyledDayCell>
                    ))}
                  </tr>
                </>
              )}
            </tbody>
          </>
        )}
      </StyledTable>
    </TableContainer>
  );
};

export default OvScanningPlanTable;

const StyledTable = styled.table`
  width: auto;
  border-collapse: collapse;
`;

const TableContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  margin: 0;
  padding: 1rem 0;
`;

const StyledDayHeader = styled.th<{ isToday: boolean }>`
  background-color: ${(props) =>
    props.isToday ? Colours.LIGHT_GREEN : 'none'};
  text-align: center;
  font-size: 0.85rem;
  width: 2rem;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  height: 3rem;
`;

const StyledMonthName = styled.div`
  padding: 0;
  margin: 0;
  font-weight: normal;
  font-size: 0.5rem;
`;

const StyledDayCell = styled.td<{ isToday: boolean }>`
  background-color: ${(props) =>
    props.isToday ? Colours.LIGHT_GREEN : 'none'};
  text-align: center;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  font-size: 0.4rem;
  white-space: initial;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  height: 2rem;
`;

const StyledLabelCellHeader = styled.th`
  font-weight: lighter;
  font-size: 0.65rem;
  text-align: left;
  position: sticky;
  z-index: 1;
  left: 0;
  background-color: ${Colours.OV_WHITE};
  border-bottom: 1px solid #ccc;
`;

const StyledLabelCell = styled.td`
  font-weight: lighter;
  font-size: 0.65rem;
  text-align: left;
  padding: 0.5rem 0.5rem;
  position: sticky;
  z-index: 1;
  left: 0;
  background-color: ${Colours.OV_WHITE};
  height: 1rem;
  border-bottom: 1px solid #ccc;
  white-space: nowrap;
`;

const StyledDayCellContent = styled.div`
  width: 1rem;
  height: 1rem;
  border-radius: ${Variables.borderRadius.CIRCLE};
  margin: auto;
`;

const StyledLhPgCellContent = styled.div`
  margin: auto;
  width: 1rem;
  height: 1rem;
  border-radius: ${Variables.borderRadius.CIRCLE};
  background-image: linear-gradient(
    to right,
    ${Colours.LIGHT_LEMON} 42%,
    ${Colours.OV_BASE} 42%,
    ${Colours.OV_BASE} 58%,
    ${Colours.BRIGHT_YELLOW} 58%
  );
  background-size: 100% 100%;
  border: 0.2rem solid ${Colours.OV_BASE};
`;

const StyledE3gCellContent = styled.div`
  margin: auto;
  width: 1rem;
  height: 1rem;
  border-radius: ${Variables.borderRadius.CIRCLE};
  background-color: ${Colours.OV_LIGHT_GRAY};
  border: 0.2rem solid ${Colours.OV_BASE};
`;

const StyledPeriodDayContent = styled.div`
  margin: auto;
  width: 1rem;
  height: 1rem;
  border-radius: ${Variables.borderRadius.CIRCLE};
  background-color: ${Colours.OV_PINK};
  border: 0.2rem solid ${Colours.OV_BASE};
`;
